import {useTableFilter, useValidation} from "starh-comp-common";
import * as yup from "yup";
import {useGlobalRenders} from "./useGlobalRenders";


export const useNotificationConfigConfig = () => {
    const { shortcuts } = useTableFilter();
    const {booleanGridRendered} = useGlobalRenders()

    const schema = yup.object().shape({
        name: yup.string().required(),
        username: yup.string().required(),
        host: yup.string().required(),
        port: yup.number().required(),
        password: yup.string().required(),
        sender: yup.string().email().required(),
        tenantIds: yup.array().of(yup.object()).required(),
        protocol: yup.object().required(),
        defaultEncoding: yup.object().required(),

    });

    const schemaModify = yup.object().shape({
        name: yup.string().required(),
        username: yup.string().required(),
        host: yup.string().required(),
        port: yup.number().required(),
        password: yup.string().required(),
        sender: yup.string().email().required(),
        tenantIds: yup.array().of(yup.object()).required(),

    });


    const fullColumns = [
        {
            header: "Name",
            field: 'name',
            sortable: true,
        },
        {
            header: "Username",
            field: 'username',
            sortable: true,
        },
        {
            header: "Host",
            field: 'host',
            sortable: true,
        },
        {
            header: "Sender",
            field: 'sender',
            sortable: true,
        },
        {
            header: "Protocol",
            field: 'protocol',
            sortable: true,
        },
        {
            header: "Default Encoding",
            field: 'defaultEncoding',
            sortable: true,
        },
        {
            header: "StartTLS",
            field: 'starttls',
            sortable: true,
            renderer: (rowData) => booleanGridRendered(rowData.starttls)
        },
        {
            header: "Default Config",
            field: 'defaultConfig',
            sortable: true,
            renderer: (rowData) => booleanGridRendered(rowData.defaultConfig)
        },

    ]
    const fullTextSearchFields = ['name', 'username']


    const advancedSearchFields = [
        shortcuts.Text("Name", 'name'),
        shortcuts.Text("Username", 'username'),
        shortcuts.Text("Host", 'host'),
        shortcuts.Bool("StartTLS", 'starttls')
    ]
    const exportFields = [
        {
            header: "Name",
            field: 'name',
        },
        {
            header: "Tenant Ids",
            field: 'tenantIds',
        },
        {
            header: "Host",
            field: 'host',
        },
        {
            header: "Port",
            field: 'port',
        },
        {
            header: "Username",
            field: 'username',
        },
        {
            header: "Password",
            field: 'password',
        },
        {
            header: "Protocol",
            field: 'protocol',
        },
        {
            header: "Default Encoding",
            field: 'defaultEncoding',
        },
        {
            header: "Sender",
            field: 'sender',
        },
        {
            header: "StartTLS",
            field: 'starttls',

        },
        {
            header: "Default Config",
            field: 'defaultConfig',

        }
    ]

    return {
        schema, fullColumns, exportFields, advancedSearchFields, fullTextSearchFields,schemaModify
    }

}
