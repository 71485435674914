import {useTableFilter, useValidation} from "starh-comp-common";
import * as yup from "yup";
import {useGlobalRenders} from "./useGlobalRenders";
import {useDefaultsConfigs} from "./useDefaultsConfigs";


export const useAppTenantMappingsConfigs = () => {
    const { shortcuts } = useTableFilter();
    const {
        string, objectRequired
    } = useValidation()
    const {joinArrayToStringGridRendered, langFlagRender} = useGlobalRenders()
    const {applicationEndpoint, tenantEndpoint} = useDefaultsConfigs();

    const schema = yup.object({
        id: string,
        application: objectRequired,
        tenant: objectRequired,
        domains: yup.array().of(string).required(),
        languages: yup.array().of(string).required()
    });

    const fullColumns = [
        {
            header: "Application",
            field: 'application',
            sortable: true,
            renderer: (rowData) => {
                return (
                    <>{rowData.application?.code}</>
                );
            }
        },
        {
            header: "Tenant",
            field: 'tenant',
            sortable: true,
            renderer: (rowData) => {
                return (
                    <>{rowData.tenant?.name}</>
                );
            }
        },
        {
            header: "Domains",
            field: 'domains',
            sortable: true,
            renderer: (rowData) => joinArrayToStringGridRendered(rowData.domains)
        },
        {
            header: "Languages",
            field: 'languages',
            sortable: true,
            renderer: (rowData) => {
                return rowData.languages.map(l => {
                    return langFlagRender(l, {height: '18px', width: '24px', border: 'solid 1px #ccc', marginRight: '3px'})
                })
            }
        }
    ]
    const fullTextSearchFields = ['application', 'tenant', 'domains']

    const advancedSearchFields = [
        shortcuts.SimpleAjaxListAsString("Application", 'application.code', applicationEndpoint, "code", "Pick an application", "code"),
        shortcuts.SimpleAjaxListAsString("Tenant", 'tenant.name', tenantEndpoint, "name", "Pick a tenant", "name"),
        shortcuts.Text("Domains", 'domains'),
        shortcuts.Text("Language", 'languages'),
    ]

    const exportFields = [
        {
            header: "Application",
            field: 'application',
        },
        {
            header: "Tenant",
            field: 'tenant',
        },
        {
            header: "Domains",
            field: 'domains',
        },
        {
            header: "Languages",
            field: 'languages',
        }
    ]

    return {
        schema, fullColumns, exportFields, advancedSearchFields, fullTextSearchFields
    }

}