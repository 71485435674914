import {useGlobalRenders} from "../../../../hooks/useGlobalRenders";

export const PublicPage = (props) => {

    const {defaultLogo} = useGlobalRenders();
    const logo = defaultLogo(80, "mr-0 lg:mr-6");

    return (
        <div className="flex  " style={{ height: '100vh' }}>
            <div className=" flex flex-wrap align-items-center justify-content-center"
                 style={{ width: '100vw', height: '100vh' }}>
                <div className="grid grid-nogutter surface-section text-800" style={{ width: '100vw', height: '100%' }}>
                    <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center">
                        <section>
                            <span className="block text-6xl font-bold mb-1">{logo}</span>
                            <div className="text-6xl text-primary font-bold mb-3">{props.title}</div>
                            <p className="mt-0 mb-4 text-700 text-4xl line-height-3">
                                {props.subtitle}
                            </p>
                            {props.children}
                        </section>
                    </div>
                    <div className="col-12 md:col-6 overflow-hidden hidden md:block h-full">
                        <img
                            src="https://picsum.photos/720/939/?random"
                            alt={""}
                            className="md:ml-auto md:block"
                            style={{ clipPath: 'polygon(8vh 0, 100vh 0vh, 100vh 100vh, 0 100vh)', height:"100vh" }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}