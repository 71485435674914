import React from "react";
import * as PropTypes from "prop-types";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import MailConfigDataTable from "../../components/MailConfigDataTable";
import {useMailLogConfig} from "../../hooks/useMailLogConfig";
import {Divider} from "primereact/divider";
import {Card} from "primereact/card";

export const LogMailDetails = (props) => {
    const {joinArrayToStringGridRendered} = useGlobalRenders()
    const {mailStatus} = useMailLogConfig()
    const data = [
        {'label': ("sender"), 'value': props.data?.sender},
        {'label': ("config"), 'value': props.data?.config},
        {'label': ("template"), 'value': props.data?.template},
        {'label': ("sent date"), 'value': props.data?.sentDate},
        {'label': ("email sent?"), 'value': mailStatus(props.data?.emailIsSent)},
        {'label': ("To"), 'value': joinArrayToStringGridRendered(props.data?.mailDestinations.TO)},
        {'label': ("CC"), 'value': joinArrayToStringGridRendered(props.data?.mailDestinations.CC)},
        {'label': ("CCN"), 'value': joinArrayToStringGridRendered(props.data?.mailDestinations.CCN)},
    ]

    return (<>
        {props.data == null && (<p>Error!</p>) || (<>
            <div className="grid fluid">
                <div className="col-12">
                    <Divider align={"center"}>
                        <span className="p-tag">{"General Data"}</span>
                    </Divider>

                    <MailConfigDataTable data={data} enableTooltip={false} keyPrefix="configDetails"/>

                    <Divider align={"center"}>
                        <span className="p-tag">{"Log Message"}</span>
                    </Divider>

                    <div className={"p-card p-3"}>
                        <div className="m-0">{props?.data?.logMessage}</div>
                    </div>
                </div>
            </div>
        </>)}
    </>)
}

LogMailDetails.propTypes = {
    data: PropTypes.object.isRequired
}

