import React, {useContext, useEffect, useState} from 'react'
import {AjaxDropdown} from "starh-comp-common";
import {useDefaultsConfigs} from "../hooks/useDefaultsConfigs";
import PropTypes from "prop-types";
import axios from "axios";
import {APPID_HEADER, TENANTID_HEADER, UserContext} from "starh-comp-auth";
import {useGlobalRenders} from "../hooks/useGlobalRenders";

export const SelectApplicationTenant = (props) => {
    const {user} = useContext(UserContext);
    const {applicationEndpoint, tenantEndpoint} = useDefaultsConfigs();
    const {langFlagRender} = useGlobalRenders();
    const [application, setApplication] = useState(undefined);
    const [tenant, setTenant] = useState(undefined);

    let endpoint = '';
    switch (props.type){
        case "APP":
            endpoint = applicationEndpoint;
            break

        case "TEN":
            endpoint = tenantEndpoint;
            break
    }

    const onChange = (sel) => {
        axios.defaults.headers[APPID_HEADER] = sel?.application?.code || user.applicationId;
        axios.defaults.headers[TENANTID_HEADER] = sel?.tenant?.name || user.tenantId;

        switch (props.type){
            case "APP":
                setApplication(sel);
                break
            case "TEN":
                setTenant(sel);
                break
        }
        props.onSelect(sel)
    }

    const dropdownTitle = (item) => {
        if (props.type == 'APP'){
            return "Select Application..."
        }else if (props.type == 'TEN') {
            return "Select Tenant..."
        }
        return props.placeholder;
    }

    const dropdownTemplate = (item) => {
        if(item){
            if (props.type == 'APP' && item?.code){
                let defLang = (item.defaultLang) ? <>
                    {langFlagRender(item.defaultLang, {height: "16px", border: 'solid 1px #ccc'},"Default Language "+item.defaultLang, "Default Language "+item.defaultLang)}
                </> : <></>;

                return (item) ? <>Application: {defLang} <b>{item.code}</b></> : <>Select Application...</>
            }else if (props.type == 'TEN' && item?.name) {
                return (item) ? <>Tenant: <b>{item.name}</b></> : <>Select Tenant...</>
            }
        }
        return props.placeholder;
    }

    return (<>
        <AjaxDropdown
            nameField={"code"}
            name={"code"}
            withController={false}
            label={""}
            placeholder={dropdownTitle()}
            baseEndpoint={endpoint}
            onChange={onChange}
            itemTemplate={(item) => dropdownTemplate(item)}
            valueTemplate={(item) => dropdownTemplate(item)}
        />
        <div className="border-bottom-1 border-gray-100 pb-3 mb-3" />
        {(application || tenant) && (<>
            {props.children}
        </>) || (<>Seleziona {(props.type == 'APP') && <>un'Application</>}{(props.type == 'TEN') && <>un Tenant</>} to access content</>)}
    </>);
}

SelectApplicationTenant.propTypes = {
    type: PropTypes.oneOf(['APP', 'TEN']).isRequired,
    placeholder: PropTypes.string.isRequired,
    onSelect: PropTypes.func
};

SelectApplicationTenant.defaultProps = {
    type: 'APP',
    placeholder: 'Select...',
    onSelect: (sel) => {}
}