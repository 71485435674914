import * as PropTypes from "prop-types";
import {useEffect} from "react";


export const Page = (props) => {
    useEffect(() => {
        document.title = "Starh - " + props.title;
    },[props.title])

    return (
        <div className="surface-section px-2 py-2 md:px-2 lg:px-2     ">
            <div className="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-2 border-bottom-1 surface-border">
                <div className="mb-1 lg:mb-0 w-full">
                    <div className="text-3xl font-medium text-900 mb-1">{props.title} {props.header}</div>
                    <div className="text-500 mr-0 md:mr-1">{props.subtitle}</div>
                </div>
                {props.toolbar}
            </div>
            <div className="pt-2 pb-2">
                {props.children}
            </div>
            {props.footer && (
                <div className="pt-4 border-top-1 surface-border">
                    {props.footer}
                </div>
            )}
        </div>
    )

}

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    footer: PropTypes.element,
    header: PropTypes.element,
    toolbar: PropTypes.any,
};