
export const helpDefaultState = {
    helpId: null,
    helpContent: null,
    helpTitle: null,
    helpExist: false,
    loading:false,
    editHelp: false,
    helpDialogOpened: false,
}
export const helpEvents = {
    "LOAD": "LOAD",
    "LOADING": "LOADING",
  //  "EDIT": "EDIT",
    "EDIT_IT": "EDIT_IT",
    "CLOSE_DIALOG":"CLOSE_DIALOG",
    "SAVE_HELP": "SAVE_HELP",
    "CHANGE_HELP_CONTENT": "CHANGE_HELP_CONTENT",
    "CHANGE_HELP_TITLE": "CHANGE_HELP_TITLE"
}


export const helpReducer = (state, action) => {

    switch (action.type) {
        case helpEvents.LOADING:
            return {...state, loading: action.payload}
        case helpEvents.LOAD:
            return {...state, helpExist:action.payload.helpExist, helpId:action.payload.id,  helpContent: action.payload.content, helpTitle:action.payload.title, editHelp: action.payload.edit, helpDialogOpened:true, loading: false}
        // case helpEvents.EDIT:
        //     return {...state, helpId:action.payload.id, helpContent: action.payload.content, helpTitle:action.payload.title, editHelp:action.payload.edit, helpDialogOpened:true,  loading: false}
        case helpEvents.EDIT_IT:
            return {...state, editHelp: action.payload}
        case helpEvents.CLOSE_DIALOG:
            return {...state, helpDialogOpened: false}

        case helpEvents.CHANGE_HELP_CONTENT:
            return {...state, helpContent: action.payload}
        case helpEvents.CHANGE_HELP_TITLE:
            return {...state, helpTitle: action.payload}


        default:
            return state;
    }



}

