import {PublicPage} from "../../components/layouts/PublicPage";
import {Messages} from "primereact/messages";
import {NavLink} from "react-router-dom";
import {Form, Password, useValidation} from "starh-comp-common";
import {Button} from "primereact/button";
import axios from "axios";
import {useContext, useRef, useState} from "react";
import {ConfigContext} from "starh-comp-auth";
import * as yup from "yup";
import {useDefaultsConfigs} from "../../../../hooks/useDefaultsConfigs";

export const PasswordResetFinish = (props) => {

    const {appId} = useContext(ConfigContext);
    const {resetPassFinishEndpoint} = useDefaultsConfigs();
    const key = new URLSearchParams(props.location.search).get('key');
    const {stringRequiredMultipleSpace} = useValidation();
    const [submitting, setSubmitting] = useState(false);
    const [complete, setComplete] = useState(false);
    const msgs = useRef(null);


    const onSubmit = (data) => {
        setSubmitting(true);

        let params = {
            newPassword: data.newPassword,
            key: key,
            appId: appId
        }
        msgs.current.clear();
        axios.post(resetPassFinishEndpoint, params)
            .then((res) => {
                setComplete(true);
                msgs.current.show([
                    {severity: 'info', summary: '', detail: 'Your password was successfully changed.', sticky: true},
                ]);
            })
            .catch(e => {
                //let status = e.response.status
                setComplete(false);
                msgs.current.show([
                    {severity: 'warn', summary: '', detail: 'Reset Key or User Not Found', sticky: true},
                ]);

            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const schema = yup.object().shape({
        newPassword: stringRequiredMultipleSpace,
        confirmPassword: stringRequiredMultipleSpace.oneOf([yup.ref('newPassword'), null], 'The Password must match')
    });

    if (key) {
        return (
            <PublicPage title={"Password Reset"} subtitle={"Enter the new password, stay strong!"}>
                <Messages ref={msgs}/>
                {complete ? (
                    <>
                        <p><NavLink to="/" className="text-2xl">Login now</NavLink></p>
                    </>
                ) : (
                    <Form schema={schema} onSubmit={onSubmit}>
                        <div className="grid responsive text-2xl">
                            <div className="col-12" >
                                <Password withController={true} name={"newPassword"} label="Password*"/>
                            </div>
                            <div className="col-12" >
                                <Password withController={true} name={"confirmPassword"} label="Repeat Password*"/>
                            </div>
                            <div className="col-12">
                                <Button type="submit" label="Set Password Now" loading={submitting} icon="pi pi-save"  className="p-button-lg"/>
                            </div>
                        </div>
                    </Form>
                )}
                <NavLink to="/" className="text-2xl">Back to Home</NavLink>
            </PublicPage>
        );
    } else {
        return null;
    }
}

