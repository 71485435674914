import React, {useContext, useRef, useState} from 'react';
import {AjaxDropdown, AjaxTable, Form, Chips, HiddenField} from "starh-comp-common";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import {Dialog} from "primereact/dialog";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import {useAppTenantMappingsConfigs} from "../../hooks/useAppTenantMappingsConfigs";
import {Page} from "../../themes/hya-prime-block/components/layouts/Page";
import {HasPermission} from "starh-comp-auth";
import axios from "axios";
import {UtilsContext} from "../../themes/hya-prime-block/components/UtilsProvider";

export const ManageAppTenantMapping = () => {
    const {
        schema, fullColumns, exportFields, fullTextSearchFields, advancedSearchFields
    } = useAppTenantMappingsConfigs()
    const {gridEditButton, gridDeleteButton, defaultPageHeader, defaultDialogFooter} = useGlobalRenders();
    const {
        appTenantConfigEndpoint, applicationEndpoint, tenantEndpoint,
        defaultDeleteItem, loadEditItem, queryStringEndpoint, getNameOrValue
    } = useDefaultsConfigs();
    const {showWarnMessage, showSuccessMessage} = useContext(UtilsContext);

    const [showDialog, setShowDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [dialogMode, setDialogMode] = useState(null);
    const [formReset, setFormReset] = useState(null);
    const tableRef = useRef();

    const entity = 'app/tenant';
    const baseEndpoint = appTenantConfigEndpoint;
    const reset = () => { setFormReset(!formReset) }
    const loadData = () => {
        if (tableRef.current) {
            tableRef.current.reload();
        }
    }
    const opItem = (op, data) => {
        setDialogMode(op);
        if(data.id) {
            loadEditItem(baseEndpoint, data, data.id, (dataItem) => {
                setCurrentData(dataItem);
                setShowDialog(true);
            })
        } else{
            setCurrentData(data);
            setShowDialog(true);
        }
    }
    const pageHeader = () => defaultPageHeader("Add " + entity, () => opItem('ADD', {}))
    const dialogFooter = () => defaultDialogFooter(dialogMode, () => reset())

    fullColumns.push({
        header: "Actions",
        headerStyle: {width: "105px"},
        renderer: (data) => {
            return <>
                {gridEditButton(() => opItem('EDIT', data), [])}
                {gridDeleteButton( () => {
                    defaultDeleteItem(baseEndpoint, data, entity, data.id, () => loadData())
                }, [])}
            </>
        }
    })

    const addEditItem = (endpoint, data, dialogMode, entity, value, successFunction = () => {}, errorFunction = () => {}) => {
        let ajax = axios.post;
        let ep = endpoint;
        if (dialogMode === "EDIT" && data.hasOwnProperty('id')) {
            ajax = axios.put;
            ep = queryStringEndpoint(endpoint, value)
        }
        ajax(ep, data)
            .then((r) => {
                const name = getNameOrValue(data, value);
                if (dialogMode === "EDIT") {
                    showSuccessMessage("Info", entity + " '" + name + "' updated successfully.")
                } else {
                    showSuccessMessage("Info", entity + " '" + name + "' added successfully.")
                }
                successFunction();
            })
            .catch(e => {
                errorFunction();
                showWarnMessage("Attention", e.response.data.detail || e.response.data.title);
            });
    }

    const submitForm = (data) => {
        addEditItem(baseEndpoint, data, dialogMode, entity, data.id, () => {
            setShowDialog(false)
            loadData();
        })
    }

    const dialogContent = () => {
        return (<>
            <HiddenField name={'id'} withController={true} value={currentData.id} />
            <div className="mb-2">
                <AjaxDropdown
                    nameField={'code'}
                    name={'application'}
                    cache={false}
                    baseEndpoint={applicationEndpoint}
                    label="Application *"
                    placeholder="Select an application..."
                    withController={true}
                    readOnly={dialogMode === 'EDIT'}
                />
            </div>
            <div className="mb-2">
                <AjaxDropdown
                    nameField={'name'}
                    name={'tenant'}
                    cache={false}
                    baseEndpoint={tenantEndpoint}
                    label="Tenant *"
                    placeholder="Select a tenant..."
                    withController={true}
                    readOnly={dialogMode === 'EDIT'}
                />
            </div>
            <div className="mb-2">
                <Chips withController={true} name="domains" label="Domains *" allowDuplicate={false}
                       helpLabel={"Write and press ENTER to insert the domain"} />
            </div>
            <div className="mb-2">
                <Chips withController={true} name="languages" label="Languages *" allowDuplicate={false}
                       helpLabel={"Write and press ENTER to insert the language"} />
            </div>
        </>)
    }

    return (<>
        <HasPermission permissions={[]}>
            <Page title={"Mapping App/Tenant"} header={pageHeader()}>
                <AjaxTable
                    innerRef={tableRef}
                    endpoint={baseEndpoint}
                    columns={fullColumns}
                    //exportFields={exportFields}
                    fullTextSearchFields={fullTextSearchFields}
                    advancedSearchFields={advancedSearchFields}
                    searchButtonLabel="Search"
                    resetButtonLabel="Reset"
                />

                <Form onSubmit={submitForm} schema={schema} defaultValues={currentData} reset={formReset}>
                    <Dialog visible={showDialog} style={{ width: '50vw' }} header={dialogMode + " " + entity} modal className="p-fluid"  appendTo="self"
                            onHide={()=>{setShowDialog(false)}} footer={dialogFooter()} >
                        {dialogContent()}
                    </Dialog>
                </Form>
            </Page>
        </HasPermission>
    </>)
}