import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    AjaxTable,
    Form,
    HiddenField,
    Input,
    SimpleDropdown,
    useValidation,
    SingleFileUpload,
    Switch
} from "starh-comp-common";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import {Dialog} from "primereact/dialog";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import {Page} from "../../themes/hya-prime-block/components/layouts/Page";
import {HasPermission} from "starh-comp-auth";
import {SelectApplicationTenant} from "../../components/SelectApplicationTenant";
import {useLabelConfigs} from "../../hooks/useLabelConfigs";
import * as yup from "yup";
import {Button} from "primereact/button";
import axios from "axios";
import {UtilsContext} from "../../themes/hya-prime-block/components/UtilsProvider";
import {FileUpload} from "primereact/fileupload";
import {confirmDialog} from "primereact/confirmdialog";

export const ManageLabels = () => {
    const {
        defaultSchema, fullColumns, fullTextSearchFields, importSchema, exportSchema
    } = useLabelConfigs()
    const {
        gridEditButton, gridDeleteButton, defaultPageHeader, defaultDialogFooter, langFlagRender, printLangLabel
    } = useGlobalRenders();
    const {
        labelEndpoint, defaultDeleteItem, loadEditItem, defaultAddEditItem,
        labelImportEndpoint, labelExportEndpoint
    } = useDefaultsConfigs();
    const {string, stringRequired} = useValidation()

    const [application, setApplication] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [dialogMode, setDialogMode] = useState(null);
    const [formReset, setFormReset] = useState(null);
    const [language, setLanguages] = useState([]);
    const [formSchema, setFormSchema] = useState({});
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentImportLanguage, setCurrentImportLanguage] = useState(null)
    const [overwriteImport, setOverwriteImport] = useState(false);
    const {showWarnMessage, showSuccessMessage} = useContext(UtilsContext);
    const tableRef = useRef();

    const entity = 'label';
    const defaultLang = 'it';
    const baseEndpoint = labelEndpoint;
    const reset = () => {
        setFormReset(!formReset)
    }
    const loadData = () => {
        if (tableRef.current) {
            tableRef.current.reload();
        }
    }

    useEffect(()=>{
        if(overwriteImport === true) {
            confirmDialog({
                message: "Attivando l'overwrite sovrascriverai le label per la lingua selezionata, vuoi procedere comunque?",
                header: "Attenzione",
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: "Ok",
                rejectLabel: "Cancel",
                accept() {},
                reject() {
                    setOverwriteImport(false);
                }
            })
        }
    },[overwriteImport])

    const opItem = (op, data) => {
        setSelectedLanguage(application.defaultLang);
        data.applicationId = application?.code;

        setDialogMode(op);
        if(op == 'IMPO'){
            setOverwriteImport(false);
            setCurrentImportLanguage(defaultLang)
            setSelectedLanguage(defaultLang);
            setShowDialog(true);
        }else if(op == 'EXPO'){
            setFormSchema(exportSchema);
            setShowDialog(true);
        }else{
            setFormSchema(defaultSchema);
            if(data.id) {
                loadEditItem(baseEndpoint, data, data.id, (dataItem) => {
                    Object.keys(application?.languages).map(l => {
                        let lang = application.languages[l];
                        dataItem['translatedValues-' + lang] = (dataItem.translatedValues && dataItem.translatedValues[lang]) ? dataItem.translatedValues[lang] : null;
                    })

                    dataItem.defaultLang = {label: dataItem.defaultLang, value: dataItem.defaultLang};
                    delete dataItem.translatedValues;

                    setSelectedLanguage(dataItem.defaultLang)
                    setCurrentData(dataItem);
                    setShowDialog(true);
                })
            } else{
                data.defaultLang = {label: data.defaultLang, value: data.defaultLang};
                delete data.translatedValues;

                setSelectedLanguage(data.defaultLang)
                setCurrentData(data);
                setShowDialog(true);
            }
        }
    }

    const pageHeader = () => {
        return (<>
            {application && (<>
                { defaultPageHeader("Add " + entity, () => opItem('ADD', {})) }

                <Button
                    icon="pi pi-cloud-download"
                    className="my-2 p-button-help p-button p-component p-button-raised p-button-rounded p-button-sm"
                    label={'Export'}
                    style={{float: 'right', marginRight: '5px'}}
                    onClick={() => opItem('EXPO', {})}
                    loading={loading}
                />
                <Button
                    icon="pi pi-cloud-upload"
                    className="my-2 p-button-info p-button p-component p-button-raised p-button-rounded p-button-sm"
                    label={'Import'}
                    style={{float: 'right', marginRight: '5px'}}
                    onClick={() => opItem('IMPO', {})}
                    loading={loading}
                />
            </>)}
        </>)
    }
    const dialogFooter = () => {
        if(dialogMode != 'IMPO'){
            return defaultDialogFooter(dialogMode, () => reset())
        }
        return (<></>);
    }

    useEffect(()=>{
        loadData();
    }, [application])


    fullColumns.push({
        header: "Default Language",
        field: 'defaultLang',
        renderer: (rowData) => {
            let defLang = application.defaultLang;
            let tooltip = rowData.translatedValues[defLang];
            return (<>
                {langFlagRender(defLang, {height: "20px", 'marginRight': '5px', border: 'solid 1px #ccc'})}
                {tooltip || '<NO TRANSLATION>'}
            </>);
        }
    })

    fullColumns.push({
        header: "Actions",
        headerStyle: {width: "105px"},
        renderer: (data) => {
            return <>
                {gridEditButton(() => opItem('EDIT', data), [])}
                {gridDeleteButton( () => {
                    defaultDeleteItem(baseEndpoint, data, entity, data.id, () => loadData(), ()=>{}, data.key)
                }, [])}
            </>
        }
    })

    const submitForm = (data) => {
        switch (dialogMode) {
            case 'IMPO':
                // La chiamata la fa direttamente il componente di upload
                break;

            case 'EXPO':
                if(data.application && data.language) {
                    setLoading(true)
                    axios.get(labelExportEndpoint +"/" + data.application + '/' + data.language, {
                            responseType: 'blob'
                        }).then(response => {
                            const fileName = response.headers["x-filename"];
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', fileName);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        })
                        .catch(err => {
                            showWarnMessage("Attenzione", "Si è verificato un errore nell'esportazione");
                        }).finally(() => {
                            setLoading(false)
                        })
                }else{
                    showWarnMessage("Attenzione", "Si è verificato un errore nell'esportazione");
                }
                break;

            case 'ADD':
                delete data.id;
            case 'EDIT':
                delete data.applicationId

                data.application = application?.code;
                data.defaultLang = data.defaultLang.value;

                data.translatedValues = {};
                Object.keys(data).map(d => {
                    if(d.includes('translatedValues-')){
                        let l = d.split('-')[1];
                        if(data[d] && data[d] != ''){
                            data.translatedValues[l] = data[d];
                        }
                        delete data['translatedValues-'+l];
                    }
                });

                defaultAddEditItem(baseEndpoint, data, dialogMode, entity, data.id, () => {
                    setShowDialog(false)
                    loadData();
                }, () => {}, data.key)
                break;
        }
    }

    const changeDefaultLanguage = (data) => {
        setCurrentImportLanguage(data);
        setSelectedLanguage(data)

        if(dialogMode == 'ADD' || dialogMode == 'EDIT'){
            let languageSchema = defaultSchema;
            application.languages.map(lang => {
                if(data == lang) {
                    languageSchema['translatedValues-' + lang] = stringRequired;
                }else{
                    languageSchema['translatedValues-' + lang] = string;
                }
            });
            setFormSchema(languageSchema);
        }
    }

    const onImportUpload = (e) => {
        try{
            let filename = e.files[0]?.name;
            showSuccessMessage("Success", "File "+filename+" importato correttamente!");

            setShowDialog(false)
            loadData();
        }catch (err){
            console.log(err);
            showWarnMessage("Attenzione", "Si è verificato un errore nell'importazione")
        }
    }
    const onImportBeforeSend = (e) => {
        e.xhr.setRequestHeader('Authorization', axios.defaults.headers['Authorization']);
    };

    const dialogContent = () => {
        let defLang = {label: defaultLang, value: defaultLang}

        if (dialogMode == 'IMPO'){

            return (<>
                <p>Seleziona il file YAML e importalo</p>
                <Switch
                    withController={false} name="overwrite" label={"Overwrite"}
                    value={overwriteImport} onChange={(value) => setOverwriteImport(value)}
                />
                <div className={"mt-2"}>
                    <SimpleDropdown
                        withController={false}
                        name="language"
                        label="Language *"
                        items={language}
                        nameField={'label'}
                        value={selectedLanguage}
                        placeholder="Select language"
                        onChange={changeDefaultLanguage}
                        itemTemplate={printLangLabel}
                        valueTemplate={(options) => printLangLabel(options, 'Select Language')}
                    />
                </div>
                <div className={"mt-3"}>
                    <label>Upload File *</label>
                    <FileUpload
                        mode="advanced"
                        name="file"
                        url={labelImportEndpoint +"/" + application.code + '/' + currentImportLanguage + "?application=" + application?.code + "&overwrite=" + overwriteImport}
                        accept="*"
                        multiple={false}
                        onBeforeSend={onImportBeforeSend}
                        chooseLabel={"Choose file"}
                        onUpload={onImportUpload}
                        uploadLabel={'Start Import'}
                        className={"adv-upload-file"}
                    />
                </div>
            </>)
        }

        if (dialogMode == 'EXPO'){
            return (<>
                <div className="col-12">
                    <HiddenField name={"language"} withController={true} value={selectedLanguage}>
                        <SimpleDropdown
                            withController={false}
                            name="languageWrapper"
                            label="Language *"
                            items={language}
                            nameField={'label'}
                            value={selectedLanguage || defLang}
                            placeholder="Select language"
                            onChange={changeDefaultLanguage}
                            itemTemplate={printLangLabel}
                            valueTemplate={(options) => printLangLabel(options, 'Select Language')}
                        />
                    </HiddenField>
                </div>
            </>)
        }

        return (<>

            { (dialogMode == 'ADD') && <HiddenField value={currentData.id} withController={true} name={'id'}/> }

            <div className="mb-2">
                <Input withController={true} name="key" label="Key *" value={currentData.key} />
            </div>

            {application?.languages && (<>
                <div className="grid">
                    <div className="col-12 md:col-12" style={{paddingBottom: '0'}}>
                        <b>Traduzioni</b>
                    </div>
                    {Object.keys(application.languages).map(t => {
                        let l = application.languages[t];
                        return (<>
                            <div className="col-12 md:col-12" key={'div-'+l}>
                                <div className="flex">
                                    <span className="p-inputgroup-addon"
                                          style={{height: '40px', padding: '2px 8px', textAlign: 'center', width: '50px'}}
                                    >
                                        {langFlagRender(l, {height: "16px"})}
                                    </span>
                                    <div className="w-full">
                                        <Input withController={true}
                                               name={'translatedValues-' + l}
                                               defaultValue={currentData['translatedValues-'+l] || ""}
                                        />
                                    </div>
                                    {(application.defaultLang == l) && <>
                                        <span className="p-inputgroup-addon"
                                              style={{height: '40px', padding: '2px 8px', textAlign: 'center', width: '80px', fontSize: "12px"}}
                                        >DEFAULT LANG</span>
                                    </>}
                                </div>
                            </div>
                        </>);
                    })}
                </div>
            </>)}
        </>)
    }

    const appSelected = (sel) => {
        if(sel && sel?.languages !== undefined) {
            let languages = [];
            let languageSchema = defaultSchema;

            languages = sel?.languages?.map(lang => {
                languageSchema['translatedValues-' + lang] = string;
                return {label: lang, value: lang};
            });

            setFormSchema(languageSchema);
            setLanguages(languages);
        }

        setApplication(sel)
        setCurrentData({
            application: sel?.code
        })
    }

    const schemaForm = (formSchema) => {
        return yup.object().shape(formSchema)
    }

    const headerTitle = () => {
        if(dialogMode == 'IMPO'){
            return 'IMPORT ' + entity;
        }
        if(dialogMode == 'EXPO'){
            return 'EXPORT ' + entity;
        }
        return dialogMode + " " + entity;
    }

    return (<>
        <HasPermission permissions={[]}>
            <Page title={"Manage Labels"} header={pageHeader()}>
                <SelectApplicationTenant
                    type={'APP'} placeholder={"Select Application..."}
                    onSelect={appSelected}
                >
                    {(application) ? (<>
                        <AjaxTable
                            innerRef={tableRef}
                            endpoint={baseEndpoint+"?application=" + application?.code}
                            columns={fullColumns}
                            fullTextSearchFields={fullTextSearchFields}
                            searchButtonLabel="Search"
                            resetButtonLabel="Reset"
                            requestHeaders={{ ...{'x-appid': application.code} }}
                        />

                        <Form onSubmit={submitForm} schema={schemaForm(formSchema)} defaultValues={currentData} reset={formReset}>
                            <Dialog visible={showDialog} style={{ width: '50vw' }}
                                    header={headerTitle} modal className="p-fluid"  appendTo="self"
                                    onHide={()=>{setShowDialog(false)}} footer={dialogFooter()} >
                                {dialogContent()}
                            </Dialog>
                        </Form>
                    </>) : (<></>)}
                </SelectApplicationTenant>
            </Page>
        </HasPermission>
    </>)
}