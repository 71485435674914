import {PublicPage} from "../../components/layouts/PublicPage";
import {Messages} from "primereact/messages";
import {Form, Input, useValidation} from "starh-comp-common";
import {Button} from "primereact/button";
import {useContext, useRef, useState} from "react";
import {ConfigContext} from "starh-comp-auth";
import axios from "axios";
import * as yup from "yup";
import {NavLink} from "react-router-dom";
import {useDefaultsConfigs} from "../../../../hooks/useDefaultsConfigs";

export const PasswordResetInit = () =>{
    //const {_t} = useTranslations();
    const {appId, tenantId} = useContext(ConfigContext);
    const {resetPassInitEndpoint} = useDefaultsConfigs();
    const {emailRequired} = useValidation();
    const [submitting, setSubmitting] = useState(false);
    const [complete, setComplete] = useState(false);

    const msgs = useRef(null);

    const onSubmit = (data) => {
        setSubmitting(true);

        let params = {
            email: data.email,
            appId: appId,
            tenantId: tenantId
        }
        msgs.current.clear();
        axios.post(resetPassInitEndpoint, params)
            .then((res) => {
            })
            .catch(e => {
            })
            .finally(() => {
                setSubmitting(false);
                setComplete(true);
                msgs.current.show([
                    {severity: 'info', summary: '', detail: 'Email sent with reset instructions!', sticky: true},
                ]);
            })


    }
    const schema = yup.object().shape({
        email: emailRequired,
    });

    return (
        <PublicPage
            title={"Reset your password"}
            subtitle={"Enter your account email to receive instructions on how to reset your password."}
        >
          <div className="pt-1 lg:pt-1">
              <Messages ref={msgs} />
            {complete ? (
                <>
                    <p>A password reset link was sent. Click the link in the email to create a new password.</p>
                </>
            ) : (
                <Form schema={schema} onSubmit={onSubmit}>
                    <div className="grid responsive">
                        <div className="col-12 text-2xl">
                            <Input type={"text"} withController={true} name={"email"} label="Your email address*"/>
                        </div>
                        <div className="col-12 pt-0">
                            <Button type="submit" label="Reset my password" loading={submitting} icon="pi pi-undo" className="p-button-lg"/>
                        </div>
                    </div>

                </Form>
            )}
              <NavLink to="/" className="text-2xl">Back to Home</NavLink>
          </div>
        </PublicPage>
    )
}