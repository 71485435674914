import {useTableFilter, useValidation} from "starh-comp-common";
import * as yup from "yup";
import {useGlobalRenders} from "./useGlobalRenders";


export const useApplicationsConfigs = () => {
    const { shortcuts } = useTableFilter();
    const {
        stringRequired, string
    } = useValidation()
    const {joinArrayToStringGridRendered, langFlagRender} = useGlobalRenders()

    const schema = yup.object().shape({
        code: stringRequired,
        description: string,
        defaultLang: stringRequired,
        languages: yup.array().of(yup.string()).required()
    });

    const fullColumns = [
        {
            header: "Code",
            field: 'code',
            sortable: true
        },
        {
            header: "Description",
            field: 'description',
            sortable: true
        },
        {
            header: "Def. Language",
            field: 'defaultLang',
            sortable: false,
            renderer: (rowData) => langFlagRender(rowData.defaultLang,{height: '18px', width: '24px', border: 'solid 1px #ccc'})
        },
        {
            header: "Languages",
            field: 'languages',
            sortable: false,
            renderer: (rowData) => {
                return rowData.languages.map(l => {
                    return langFlagRender(l, {height: '18px', width: '24px', border: 'solid 1px #ccc', marginRight: '3px'})
                })
            }
        }
    ]
    const fullTextSearchFields = ['code', 'description']

    const advancedSearchFields = [
        shortcuts.Text("Code", 'code'),
        shortcuts.Text("Description", 'description'),
        shortcuts.Text("Default Lang", 'defaultLang'),
    ]

    const exportFields = [
        {
            header: "Code",
            field: 'code',
        },
        {
            header: "Description",
            field: 'description',
        },
        {
            header: "Default Language",
            field: 'defaultLang',
        },
        {
            header: "Languages",
            field: 'languages',
        }
    ]

    return {
        schema, fullColumns, exportFields, advancedSearchFields, fullTextSearchFields
    }

}