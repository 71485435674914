import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {Loading, HasPermission} from "starh-comp-auth";
import axios from "axios";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {confirmDialog} from "primereact/confirmdialog";
import {TreeTable} from "primereact/treetable";
import { Column } from 'primereact/column';
import {UtilsContext} from "../../themes/hya-prime-block/components/UtilsProvider";
import {Page} from "../../themes/hya-prime-block/components/layouts/Page";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import {
    Form,
    HiddenField,
    Input,
    Number, SearchablePickList,
    SimpleDropdown,
    Switch
} from "starh-comp-common";
import {useMenuItemsConfigs} from "../../hooks/useMenuItemsConfigs";

export const ManageMenuItems = (props) => {
    let { appID, menuID } = useParams();

    const {menuEnpoint, permissionEndpoint, menuItemEndpoint} = useDefaultsConfigs();
    const {defaultPageHeader, defaultDialogFooter} = useGlobalRenders();
    const {schema} = useMenuItemsConfigs();
    const {showSuccessMessage, showWarnMessage, showInfoMessage} = useContext(UtilsContext);
    const history = useHistory();

    const [dialogMode, setDialogMode] = useState(null); // NEW, EDIT, DETAIL
    const [menuItemObj, setMenuItemObj] = useState({});
    const [loading, setLoading] = useState(false);
    const [menuInfo, setMenuInfo] = useState(false);
    const [itemDialog, setItemDialog] = useState(false);
    const [items, setItems] = useState(null);
    const [menuList, setMenuList] = useState(null);
    const [currentMenuList, setCurrentMenuList] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [formReset, setFormReset] = useState(null);
    const [redirect, setRedirect] = useState(true);
    const [sourcePermissions, setSourcePermissions] = useState([]);
    const [targetPermissions, setTargetPermissions] = useState([]);
    const [allPermissions, setAllPermissions] = useState([]);

    const entity = 'menu-item';
    const pageHeader = () => defaultPageHeader("Add " + entity, () => addItem({}))
    const dialogFooter = () => defaultDialogFooter(dialogMode, () => reset())

    useEffect(()=>{
        axios.defaults.headers['x-appid'] = appID;
        loadInfo();
        loadItems();
        loadAllPermissions()
    },[])
    const reset = () => { setFormReset(!formReset) }

    const addItem = (data) => {
        reset();
        retrieveMenuList(menuList)
        setMenuItemObj(data)
        setSourcePermissions(allPermissions)
        setTargetPermissions([])

        setDialogMode('NEW');
        setItemDialog(true);
    }

    const confirmDeleteItem = (item) => {
        confirmDialog({
            message: "vuoi rimuovere \""+item.data.label+"\" item?",
            header: "Attenzione",
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Ok",
            rejectLabel: "Cancel",
            accept() {
                // @todo: delete item
                setLoading(true);
                axios.delete(menuItemEndpoint + "/" + item.key, {
                    headers: {...{'x-menuid': menuID}}
                })
                    .then((r) => {
                        showInfoMessage("Info", "Item rimosso correttamente");
                        reset();
                        loadItems();
                        setLoading(false);
                    })
                    .catch(e => {
                        setLoading(false);
                        showWarnMessage("Attenzione", e.response.data.detail);
                    });
            },
            reject() {
                // FOO
                console.log('do nothing')
            }
        });
    }

    const loadInfo = (data) => {
        setLoading(true);
        let ajax = axios.get;
        let endpoint = menuEnpoint+"/"+menuID;

        ajax(endpoint, data, {
            headers: {...{'x-menuid': menuID, 'x-appid': appID}}
        })
            .then((r) => {
                setMenuInfo(r.data)
            })
            .catch(e => {
                console.log(e);
                setLoading(false);
            });
    };

    const retrieveMenuList = (menu, currentId = '', c = [], i= 1, liv = ' ') => {
        c[0] = {label: 'NO PARENT', value: null};
        menu.map((el, index) => {
            if(el.data?.id != currentId){
                c[i++] = {label: liv + el.data.label, value: el.data.id}
                if(el.children.length > 0){
                    retrieveMenuList(el.children, currentId, c, i++,'-'+liv);
                }
                i++;
            }
        });
        setCurrentMenuList(c);
    }

    const loadItems = (data) => {
        setLoading(true);
        let ajax = axios.get;
        let endpoint = menuEnpoint+"/"+menuID+"/children";

        ajax(endpoint, data, {
            headers: {...{'x-menuid': menuID}}
        })
            .then((r) => {
                setItems(r.data)
                setMenuList(r.data);
                setLoading(false);
            })
            .catch(e => {
                console.log(e);
                setLoading(false);
                showWarnMessage("Attenzione", e.response.data.detail || e.response.data.title);
            });
    };

    const loadItem = (id) => {
        setMenuItemObj({});
        let ajax = axios.get;
        let endpoint = menuItemEndpoint + "/"+id;

        ajax(endpoint, {
            headers: {...{'x-menuid': menuID}}
        })
            .then((r) => {
                retrieveMenuList(menuList, r.data.id)
                let perms = allPermissions.filter(p => r.data.permissions.includes(p.name));
                r.data.permissions = perms;
                onPermissionChange(perms)

                reset();
                setMenuItemObj(r.data)

                setDialogMode('EDIT');
                setItemDialog(true);
            })
            .catch(e => {
                console.log(e)
                showWarnMessage("Attenzione", e.response.data.detail || e.response.data.title);
            });
    };

    const saveItem = (data) => {
        data.permissions = (data.permissions && data.permissions.length > 0) ? data.permissions.map((p) => {
            return p.name;
        } ): [];
        delete data.createdBy;
        delete data.createdDate;
        delete data.lastModifiedBy;
        delete data.lastModifiedDate;

        setLoading(true);
        let ajax = axios.post;
        let endpoint = menuItemEndpoint;
        if (dialogMode == "EDIT" && data.hasOwnProperty("id")) {
            ajax = axios.put;
            endpoint += "/" + data.id
        }

        ajax(endpoint, data, {
            headers: {...props.requestHeaders}
        })
            .then((r) => {
                setLoading(false);
                showSuccessMessage("Attenzione", dialogMode+": item "+data.key+" correttamente")
                if (dialogMode == "EDIT") {
                    //props.onEditSuccess(data);
                } else {
                    //props.onSaveSuccess(data);
                }
                loadItems();
                hideDialog();
            })
            .catch(e => {
                setLoading(false);
                console.log(e);
                showWarnMessage("Attenzione", e.response.data.detail || e.response.data.title);
            });
    };

    const hideDialog = () => {
        reset();
        setMenuItemObj({})
        setItemDialog(false);
    }

    const dialogHeaderTitle = () => {
        if (dialogMode === "NEW") {
            return "Add new menu item"
        } else if (dialogMode === "EDIT") {
            return "Edit menu item \"" + menuItemObj.label+"\""
        } else {
            return "Add/Edit"
        }
    }

    const actionTemplate = (item, column) => {
        return (<div>
            <Button icon="pi pi-pencil" className="p-button-info p-mr-2" loading={loading}
                    onClick={() => loadItem(item.key)} tooltip={"Edit"}
                    style={{marginRight: '5px'}} tooltipOptions={{position: 'bottom'}}/>
            <Button icon="pi pi-trash" className="p-button-danger" loading={loading}
                    onClick={() => confirmDeleteItem(item)}
                    tooltip={"Delete"} tooltipOptions={{position: 'bottom'}}/>
        </div>);
    }

    const loadAllPermissions = () =>{
        axios.get(permissionEndpoint)
            .then((res) =>{
                let values = res.data.content;
                values.sort((a, b) => a.name.localeCompare(b.name));
                setAllPermissions(values)
            })
            .catch((err)=>{})
            .finally(()=>{});
    }

    const onPermissionChange = (value) => {
        setTargetPermissions(value);
        const filtered = allPermissions.filter(p => !value.some((e)=>{return e.name === p.name}));
        //console.log([value, filtered])
        setSourcePermissions(filtered)
    }

    const permissionItemTemplate = (item) => {
        return (
            <p><b>{item.name}</b><br/>{item.description}</p>
        );
    }

    const dialogContent = () => {
        if (dialogMode === "NEW" || dialogMode === "EDIT") {
            return (<>
                {loading && <Loading/>}
                {!loading && <>
                    <div className="grid fluid">
                        <HiddenField name="id" value={menuItemObj?.id} withController={true} />
                        {/*<HiddenField name="createdBy" value={menuItemObj?.createdBy} withController={true} />*/}
                        <HiddenField name={"menuId"} value={menuID} withController={true}/>

                        <div className="col-12 md:col-6">
                            <Input withController={true} name={'label'} value={menuItemObj?.label} label={'Label *'} />
                        </div>
                        <div className="col-12 md:col-6">
                            <Input withController={true} name={'tooltip'} value={menuItemObj?.tooltip} label={'Tooltip'} />
                        </div>
                        <div className="col-12 md:col-6">
                            <Input withController={true} name={'url'} value={menuItemObj?.url} label={'Url'} />
                        </div>
                        <div className="col-12 md:col-6">
                            <HiddenField name={'redirect'} withController={true} label={'Redirect'} value={redirect}>
                                <Switch
                                    withController={false}
                                    name={'redirectfield'}
                                    value={redirect}
                                    onChange={(val)=>{setRedirect(val)}}
                                />
                            </HiddenField>
                        </div>
                        <div className="col-12 md:col-6">
                            <Input withController={true} name={'icon'} value={menuItemObj?.icon} label={'Icon'} />
                        </div>
                        <div className="col-12 md:col-6">
                            <Input withController={true} name={'style'} value={menuItemObj?.style} label={'style'} />
                        </div>
                        <div className="col-12 md:col-6">
                            <Number withController={true} name={'order'} label="Order"/>
                        </div>
                        <div className="col-12 md:col-6">
                            <SimpleDropdown
                                nameField={'label'}
                                name={'parentId'}
                                items={currentMenuList}
                                withController={true}
                                label="Parent"
                                placeholder="Select Parent"
                            />
                        </div>
                        <div className="col-12">
                            <SearchablePickList
                                dataKey="name"
                                border={false}
                                withController={true}
                                label={"Permission"}
                                available={"Avaiable"}
                                selected={"Selected"}
                                name={"permissions"}
                                maxResults={2500}
                                nameField={"name"}
                                searchplaceholder={"Search permissions"}
                                itemTemplate={permissionItemTemplate}
                                searchable={true}
                                onChange={onPermissionChange}
                                value={targetPermissions}
                                idProperty={"name"}
                                source={sourcePermissions}
                            />
                        </div>
                    </div>
                </>}

            </>)
        }
    }

    const pageFooter = <Button label="Back to menus list" icon="pi pi-angle-left" className="p-button p-button-secondary" onClick={()=>history.push("/menu")} />

    return (<>
        <HasPermission permissions={["MENU_MANAGE", "MENUITEM_MANAGE"]}>
            <Page title={"'"+menuInfo?.name+"' items"} header={pageHeader()} footer={pageFooter}>
                {items && (<>
                    <TreeTable value={items} loading={loading} style={{ marginTop: '.5em' }} className="treetable-responsive">
                        <Column field="label" header="Label" expander></Column>
                        <Column field="url" header="Url" headerClassName="sm-invisible" bodyClassName="sm-invisible"></Column>
                        <Column field="tooltip" header="Tooltip" headerClassName="sm-invisible" bodyClassName="sm-invisible"></Column>
                        <Column body={(item, column) => {
                            return (
                                <span key={item.data.permissions?.name}>{item.data.permissions?.length || '0'} Permissions</span>
                            );
                        }} header="Permissions" headerClassName="sm-invisible" bodyClassName="sm-invisible" style={{textAlign:'center', width: '10em'}} />
                        <Column field="order" header="Order" headerClassName="sm-invisible" bodyClassName="sm-invisible" style={{textAlign:'center', width: '5em'}}></Column>
                        <Column body={actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                    </TreeTable>
                    <br/>
                    <br/>
                </>) || (<>
                    {loading && (<>
                        <div className="col-12">
                            <Loading/>
                        </div>
                    </>) || (<>
                        <h4>Nessun item trovato</h4>
                    </>)}
                </>)}

                <Form onSubmit={saveItem} schema={schema} defaultValues={menuItemObj} reset={formReset}>
                    <Dialog visible={itemDialog} style={{width: '70vw'}}
                            header={dialogHeaderTitle} modal className="p-fluid manage-labels" appendTo="self"
                            onHide={hideDialog} footer={dialogFooter} >
                        {dialogContent()}
                    </Dialog>
                </Form>
            </Page>
        </HasPermission>
    </>)
}