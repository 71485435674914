import React, {useRef} from 'react'
import {StyleClass} from "primereact/styleclass";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {Ripple} from "primereact/ripple";

const SidebarMenuLink = (props) => {
    const ToggleSubMenuRef = useRef(null);

    const iconClasses = (classes, iconClass) => {
        let c = classes;
        c += (iconClass) ? " pi " + iconClass : '';
        return c;
    }

    const defaultTemplateLink = [
        {   // FIRST LEVEL
            linkClasses: "p-ripple p-element flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:surface-800 text-white border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors",
            ulChildClasses: "list-none pl-2 pr-0 py-0 lg:p-2 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out static border-round-right lg:absolute left-100 top-0 z-1 text-white bg-gray-900 shadow-none lg:shadow-2 w-full lg:w-15rem",
            template: <>  {/* FIRST LEVEL */}
                <i className={iconClasses("mr-2 lg:mr-0 mb-0 lg:mb-0 text-base lg:text-lg", props.iconClass)}></i>
                <span className="font-medium inline text-base lg:text-md lg:block">{props.title}</span>
                {props.child && <i className="pi pi-chevron-down ml-auto md:hidden"></i>}
                <Ripple />
            </>
        },
        {   // SECOND LEVEL
            linkClasses: "p-ripple p-element flex align-items-center cursor-pointer p-3 hover:surface-800 hover:text-white border-round text-600 hover:text-700 text-white transition-duration-150 transition-colors",
            ulChildClasses: "list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out",
            template: <>
                <i className={iconClasses("mr-2", props.iconClass)}></i>
                <span className="font-medium">{props.title}</span>
                {props.child && <i className="pi pi-chevron-down ml-auto"></i>}
                <Ripple />
            </>
        },
        {   // THIRD LEVEL
            linkClasses: "p-ripple p-element flex align-items-center cursor-pointer p-3 hover:surface-800 hover:text-white border-round text-600 hover:text-700 text-white transition-duration-150 transition-colors",
            ulChildClasses: "",
            template: <>
                <i className={iconClasses("mr-2", props.iconClass)}></i>
                <span className="font-medium inline text-base lg:text-md lg:block">{props.title}</span>
                <Ripple />
            </>
        }
    ]
    const levelConfig = defaultTemplateLink[props.level] || undefined;

    const templateLink = (
        <NavLink
            ref={ToggleSubMenuRef}
            key={'a-' + props.level + props.linkName}
            selector={props.linkName}
            exact={props.exact || false}
            to={props.href || '#'}
            className={levelConfig?.linkClasses || ''}
            activeClassName="navlink-active"
            style={{textDecoration: 'none'}}
            onClick={(e)=>{
               if(props.href){
                   var sidebar = document.getElementById("sidebar-wrapper");
                   sidebar.classList.add("hidden");
               }
            }}
        >
            {levelConfig.template}
        </NavLink>
    );

    return (<>
        {levelConfig && (<>
            {(props.child) ? (<>
                <StyleClass
                    nodeRef={ToggleSubMenuRef} selector="@next" enterClassName="hidden" enterActiveClassName="scalein"
                    leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick
                >
                    {templateLink}
                </StyleClass>

                <ul key={'ul-'+props.linkName} id={props.selector} className={levelConfig.ulChildClasses || ''}>
                    {props.child?.map((link, key) => {
                        const linkName = props.linkName + '-' + key;
                        return (
                            <li className="lg:relative" key={'li-' + linkName + '-' + key}>
                                <SidebarMenuLink
                                    templateLink={props.templateLink} child={link.child} selector={linkName} linkName={linkName}
                                    exact={link.exact} href={link.href} iconClass={link.iconClass} title={link.title} level={props.level + 1}
                                />
                            </li>
                        )
                    })}
                </ul>
            </>) : templateLink}
        </>)}
    </>);
}

SidebarMenuLink.defaultProps = {
    level: 0
}

SidebarMenuLink.propTypes = {
    child: PropTypes.arrayOf(PropTypes.object),
    selector: PropTypes.string.isRequired,
    templateLink: PropTypes.element,
    linkName: PropTypes.string,
    exact: PropTypes.bool,
    href: PropTypes.string,
    title: PropTypes.string,
    iconClass: PropTypes.string,
    level: PropTypes.number
}

export default SidebarMenuLink;