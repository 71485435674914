import React, {useRef} from "react";
import {Button} from "primereact/button";
import {Menu} from "primereact/menu";
import PropTypes from "prop-types";

export const PopupMenu = (props) => {
    const menu = useRef(null);
    
    return (<>
        <Button
            icon={props.buttonIcon}
            className={props.classe}
            onClick={(event) => {
                menu.current.toggle(event)
            }}
        />
        <Menu
            id={props.id}
            ref={menu}
            model={props.items}
            popup={props.popup}
        />
    </>);
}

PopupMenu.propTypes = {
    id: PropTypes.string,
    classe: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    popup: PropTypes.bool,
    buttonIcon: PropTypes.string,
    onClick: PropTypes.func
}

PopupMenu.defaultProps = {
    id: '',
    classe: '',
    items: [{}],
    popup: true,
    buttonIcon: 'pi pi-bars'
}