import {useTranslations,useValidation,Form,Password} from "starh-comp-common";
import {Button} from 'primereact/button';
import * as yup from "yup";
import axios from "axios";
import {useContext, useState} from "react";
import {UtilsContext} from "../../themes/hya-prime-block/components/UtilsProvider";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";


export const ChangePassword = () => {
    const [submitting, setSubmitting] = useState(false);
    const {showWarnMessage, showInfoMessage} = useContext(UtilsContext);
    const {resetPassEndpoint} = useDefaultsConfigs();
    const {_t} = useTranslations();
    const {
        stringRequiredMultipleSpace
    } = useValidation()

    const schema = yup.object().shape({
        currentPassword: stringRequiredMultipleSpace,
        newPassword: stringRequiredMultipleSpace,
        confirmPassword: stringRequiredMultipleSpace.oneOf([yup.ref('newPassword'), null], _t("user.profile-user.password-match")),
    });

    const onSubmit = async (data, e) => {
        setSubmitting(true);
        axios.post(resetPassEndpoint, {
            currentPassword: data.currentPassword,
            newPassword: data.newPassword,
        }).then((res) => {
            e.target.reset();
            setSubmitting(false);
            showInfoMessage("Info", "Password Updated Succesfully!", 10000, false)
        }).catch(err => {
            setSubmitting(false);
            console.log(err)
            if(err?.response?.status === 400){
                showWarnMessage("Attention", "Current password does not match, please enter the correct password!", 10000, false)
            }else {
                showWarnMessage("Attention", "An error occurred!", 10000, false)
            }
        })
    };

    return (
        <div id="account-change-password">
            <Form onSubmit={onSubmit} schema={schema}>
                <div className="card">
                    <div className="flex justify-content-center  ">
                        <div className="flex align-items-center justify-content-center  m-2">
                            <div className="grid fluid">
                                <div className="col-6 col-offset-3">
                                    <Password withController={true} name={"currentPassword"}
                                              label="Current Password" />
                                </div>
                                <div className="col-6 col-offset-3">
                                    <Password withController={true} name={"newPassword"}
                                              label="New Password" />
                                </div>
                                <div className="col-6 col-offset-3">
                                    <Password withController={true} name={"confirmPassword"}
                                              label="Confirm new password" />
                                </div>
                                <div className="col-6 col-offset-3 text-center">
                                    <Button  className={"p-button-success"} type="submit" label="Update" loading={submitting}
                                            icon="pi pi-save"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
}

