import React from "react";
import PropTypes from "prop-types";
import {Tooltip} from "primereact/tooltip";

export const TemplateDataTable = (props) => {

    const color = '#CCCCCC';
    const bgColor= '#F2F1EF';
    const data = props.data;

    const dataGrid = data.map((item, key) => {
        return (
            <div className='col md:col-12 border-bottom-1 border-right-1 pl-0 p-0' style={{textAlign: 'left', borderColor: color}} key={props.keyPrefix+'-'+key}>
                <div className=" flex flex-row p-0 m-0" data-pr-tooltip={key} style={{height: "100%"}}>
                    <div data-pr-tooltip={item.label || 'LABEL_MISSING'} className="labeltooltip item-label p-2 uppercase"  style={{backgroundColor: bgColor,padding:"0.5rem", minWidth: props.minWidth || '90px' }}><i>{item.label || 'LABEL_MISSING'} </i></div>
                    <div data-pr-tooltip={item.tooltip} className="statustooltip p-2" style={{padding:"0.5rem" , overflow: 'hidden'}}>{item.value || '-'}</div>
                </div>
            </div>
        )
    })

    return (<>
        {props.enableTooltip && (<>
            <Tooltip target=".statustooltip"
                     tooltipOptions={{className: 'teal-tooltip', position: 'bottom'}}/>
            <Tooltip target=".labeltooltip" mouseTrackTop mouseTrack={20}
                     style={{fontSize: '12px', marginLeft: '10px'}}/>
        </>)}

        <div className="grid fluid m-0 border-top-1 border-left-1" style={{borderColor: color}}>
            {dataGrid}
        </div>
    </>);
}

TemplateDataTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    keyPrefix: PropTypes.string.isRequired,
    enableTooltip: PropTypes.bool,
    minWidth: PropTypes.string,
};