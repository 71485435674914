import React from 'react';
import {menu} from "../../menu.js";
import SidebarMenuLink from "./SidebarMenuLink";
import PropTypes from "prop-types";

const MenuTree = (props) => {
    return menu[props.menu].map((link, key) => {
        const linkName = props.menu.trim().toLowerCase() + key;
        return (
            <li className={link.child && "lg:relative"} key={'ml-' + linkName}>
                <SidebarMenuLink
                    child={link.child} selector={linkName} linkName={linkName}
                    exact={link.exact} href={link.href} iconClass={link.iconClass} title={link.title}
                />
            </li>
        )
    })
}

MenuTree.defaultProps = {
    menu: ''
}

MenuTree.propTypes = {
    menu: PropTypes.string.isRequired
}

export default MenuTree;