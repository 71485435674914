import React, {useContext, useState} from 'react';
import * as PropTypes from "prop-types";

import {Dialog} from "primereact/dialog";
import {HelpContext} from "../context/HelpContext";
import {helpEvents} from "./HelpReducer";
import {HasPermission, UserContext} from "starh-comp-auth";
import {TabPanel, TabView} from "primereact/tabview";
import {Editor} from "primereact/editor";
import {Button} from "primereact/button";
import axios from "axios";
import {InputText} from "primereact/inputtext";


export const InlineHelp = () => {

    const { helpState,helpDispatch } = useContext(HelpContext)
    const {user} = useContext(UserContext);
    const [textHelp, setTextHelp] = useState('');

    const closeDialog = () => {
        helpDispatch({
            type: helpEvents.CLOSE_DIALOG,
            payload:null
        })
    }

    const saveHelp = () => {
        helpDispatch({
            type: helpEvents.CHANGE_HELP_CONTENT,
            payload: textHelp.htmlValue
        })
        let payload = {
            id:    helpState.helpId,
            title :helpState.helpTitle,
            content: textHelp.htmlValue,
            applicationId:user.appId

        }
        if(helpState.helpExist) {
            axios.put("/gateway/help/"+helpState.helpId, payload)
                .then((res) => { // eslint-disable-line no-unused-vars
                    helpDispatch({
                        type: helpEvents.CLOSE_DIALOG,
                        payload:null
                    })
                    return true;
                })

        }else{
            axios.post("/gateway/help/", payload)
                .then((res) => { // eslint-disable-line no-unused-vars
                    helpDispatch({
                        type: helpEvents.CLOSE_DIALOG,
                        payload:null
                    })
                })
        }




    }

    const dialogFooter =
        <div>
            <Button label="Close" icon="pi pi-times" onClick={closeDialog} className="p-button-text" />
            {helpState.editHelp && <Button label="Save" icon="pi pi-check" onClick={saveHelp} autoFocus /> }
        </div>

    const dialogHeader = <div className={"flex flex-row align-content-center justify-content-center align-items-center"}>
        <div>
        <HasPermission mode={"ONE"} permissions={["HELP_MANAGE"]} showPanel={false}>
            {!helpState.editHelp && <Button  icon="pi pi-pencil"  className={"p-button-rounded p-button-text"} onClick={()=>{
                helpDispatch({
                    type: helpEvents.EDIT_IT,
                    payload:true
                })
            }} autoFocus /> }
            {helpState.editHelp && <Button  icon="pi pi-times"  className={"p-button-rounded p-button-text"} onClick={()=>{
                helpDispatch({
                    type: helpEvents.EDIT_IT,
                    payload:false
                })
            }} autoFocus /> }

        </HasPermission>
        </div>
        <div >{helpState.helpTitle}</div>
    </div>

    return <>
        <Dialog header={dialogHeader} visible={helpState.helpDialogOpened} style={{ width: '75vw', height: '720px' }}  onHide={closeDialog} footer={dialogFooter}>

            {helpState.editHelp &&
                <HasPermission mode={"ONE"} permissions={["HELP_MANAGE"]} showPanel={false}>
                    <InputText type="text" className="p-inputtext-lg block w-full" placeholder="Help Title ..."
                               value={helpState.helpTitle}
                               onChange={(e) => {
                                   helpDispatch({
                                       type: helpEvents.CHANGE_HELP_TITLE,
                                       payload: e.target.value
                                   })
                               }}/>
                    <TabView className={"m-0 p-0"}>
                        <TabPanel header="Edit Help Content" className={"m-0 p-0"} >
                            <Editor
                                style={{height: '375px'}}
                                value={helpState.helpContent}
                                className="editor-resize m-0 p-0"
                                onTextChange={(value) => setTextHelp(value)}
                            />
                        </TabPanel>
                        <TabPanel header="Help Preview" className={"m-0 p-0"}>
                            <div dangerouslySetInnerHTML={{__html: helpState.helpContent}}/>
                        </TabPanel>
                    </TabView>
                </HasPermission>
            }
            {!helpState.editHelp && <>
                <div dangerouslySetInnerHTML={{__html: helpState.helpContent}}/>
            </>}
        </Dialog>
    </>
}

InlineHelp.propTypes = {
    value: PropTypes.string
}