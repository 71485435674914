import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Button} from 'primereact/button';
import {PageAsForm} from "./PageAsForm";
import axios from "axios";
import {Dialog} from "primereact/dialog";
import {useHistory} from "react-router-dom";
import {confirmDialog} from "primereact/confirmdialog";
import * as PropTypes from "prop-types";
import {UtilsContext} from "../UtilsProvider";
import {Translate,useTranslations} from "starh-comp-common";
import {hasOnePermission, HasPermission, UserContext} from "starh-comp-auth";
import {Access} from "../../../../pages/Access";
import {helpDefaultState, helpEvents, helpReducer} from "../../../../components/HelpReducer";
import {InlineHelp} from "../../../../components/InlineHelp";
import {HelpContext} from "../../../../context/HelpContext";

export const FXFormPage = (props) => {

    const [value, setValue] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [formReset, setFormReset] = useState(false);
    const {user} = useContext(UserContext);
    const {showWarnMessage} = useContext(UtilsContext);
    const [helpState,helpDispatch] = useReducer(helpReducer, helpDefaultState);

    const history = useHistory();
    const {_t} = useTranslations()

    let error = {
        500: (data) => {return _t("generic.exc-internal-server-error")}, // eslint-disable-line no-unused-vars
        409: (data) => {return _t("generic.exc-conflict")}, // eslint-disable-line no-unused-vars
        400: (data) => {return _t("generic.exc-bad-request")}, // eslint-disable-line no-unused-vars
        404: (data) => {return _t("generic.exc-not-found")}, // eslint-disable-line no-unused-vars
        403: (data) => {return _t("generic.exc-forbidden")}, // eslint-disable-line no-unused-vars
        422: (data) => {return _t("generic.exc-concurrency")}, // eslint-disable-line no-unused-vars
    }

    const getAxios = (data) =>{
        return props.formMethod === "POST" ? axios.post(props.endpoint, data, {headers: {...props.requestHeader}}) : axios.put(props.endpoint.concat("/").concat(data.id), data, {headers: {...props.requestHeader}})
    }

    const sendData = (data) => {
        if(data) {
            setSubmitting(true);

            getAxios(data)
                .then((r) => {

                    setValue(r.data);
                    setSubmitting(false);
                    setShowMessage(true);
                    reset();
                })
                .catch(e => {
                    setSubmitting(false);
                    let errors = {...error, ...props.errorResponse(e)}
                    let message = errors[e?.response?.status](data)
                    showWarnMessage(_t("Error in creation"), message, 3000, false);
                });
        }
    }

    function filterData(data) {
        Object.keys(data).forEach((key) => {
            if(data[key] === "") {
                data[key] = undefined
            }
        })
        return data;
    }
    function isPromise(p) {
        if (typeof p === 'object' && typeof p.then === 'function') {
            return true;
        }

        return false;
    }
    const onSubmit = async (data) => {
        if (props.confirmBeforeSend) {

            let new_data = filterData(data)
            new_data = props.beforeSend(new_data);
            if(new_data) {

                setSubmitting(true)
                let resultMessage = props.confirmBeforeSendMessage(data)
                if(isPromise(resultMessage)){
                    resultMessage.then((d)=>{
                        setSubmitting(false)
                        confirmDialog({
                            message: d,
                            header: ("you will return to the previous page, are you sure you want to continue?"),
                            icon: 'pi pi-exclamation-triangle',
                            acceptLabel: props.confirmAcceptLabel ? props.confirmAcceptLabel : _t("Ok"),
                            rejectLabel: props.confirmRejectLabel ? props.confirmRejectLabel : _t("Cance"),
                            accept() {

                                sendData(new_data);
                            },
                            reject() {
                                // FOO
                            }
                        });
                    })
                }else {
                    setSubmitting(false)
                    confirmDialog({
                        message: resultMessage,
                        header: _t("generic.dialog-headerattention"),
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: props.confirmAcceptLabel || _t("generic.ok"),
                        rejectLabel: props.confirmRejectLabel || _t("generic.cancel"),
                        accept() {

                            sendData(new_data);
                        },
                        reject() {
                            // FOO
                        }
                    });
                }
            }
        } else {
            data = filterData(data)
            data = props.beforeSend(data);
            sendData(data);
        }
    };

    const confirmGoHome = (e) => {
        e.preventDefault()
        confirmDialog({
            message: props.gotoHomeMessage,
            header: _t("Go home"),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: _t("No"),
            rejectLabel: _t("Yes"),
            accept() {
                // FOO
            },
            reject() {
                setShowMessage(false)
                history.push(props.returnPage);
            }
        });
    }

    const confirmClearFields = (e) => {
        e.preventDefault()
        confirmDialog({
            message: _t("Are you sure you want to clear all the fields, you won't be able to go back"),
            header: _t("Clear all fields"),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: _t("No"),
            rejectLabel: _t("Yes"),
            accept() {
                // FOO
            },
            reject() {
                setShowMessage(false)
                reset();
            }
        });
    }

    const dialogFooter = () => {
        if (showMessage) {
            return (<>
                <div className="p-d-flex p-jc-center">
                    <center>
                        <Button label={"OK"} onClick={() => {
                            history.push(props.returnPage)
                        }} autoFocus/>
                    </center>
                </div>
            </>)
        }
    }
    const onSubmitClick = () =>{
        if(props.scroll){
            window.scrollTo(0, 0)
        }
    }

    const getHelp = () => {
        return axios.get("/gateway/help/"+ user.appId + "-" + props.pageId);
    }

    const showHelp = () => {

        helpDispatch({
            type: helpEvents.LOADING,
            payload:true
        })

        getHelp().then((res)=>{
            helpDispatch({
                type: helpEvents.LOAD,
                payload:{
                    content: res.data.content,
                    title: res.data.title,
                    id: res.data.id,
                    edit: false,
                    helpExist: true,
                }
            })

        }).catch(()=>{
                if(props.pageId && hasOnePermission(user, ["HELP_MANAGE"])) {
                    helpDispatch({
                        type: helpEvents.LOAD,
                        payload:{
                            content: null,
                            title: null,
                            id: user.appId + "-" + props.pageId,
                            edit: true,
                            helpExist: false,
                        }
                    })
                }else{
                    showWarnMessage(_t("generic.warning"), _t("generic.helpnotfound"));
                }
            }).finally(()=>{
            helpDispatch({
                type: helpEvents.LOADING,
                payload:false
            })
        })


    }

    const pageFormHeader = <>
        <HasPermission mode="ONE" showPanel={false} permissions={['HELP_MANAGE']}>
            {!helpState.loading &&  <span  className="pi pi-question cursor-pointer text-xl border-circle border-1 p-1  text-purple-500 font-bold transition-colors transition-duration-100 hover:text-purple-200" icon="" onClick={showHelp}/> }
            {helpState.loading &&  <span  className="pi pi-spin pi-spinner text-xl border-circle border-1 p-1  text-purple-500 font-bold transition-colors transition-duration-100 hover:text-purple-200" icon="" /> }
        </HasPermission>
    </>

    const footer = (<>
        {props.showSubmit && (
            <>
        <Button type="submit" label={props.saveLabel || _t("Save")} loading={submitting} icon="pi pi-save" onClick={onSubmitClick}
                style={{marginRight: '.25em', float: "right"}} className="p-button-success"/>

        {props.additionalFooter }

            </>
                )}
        {props.showGoBack && (

        <Button label={_t("Cancel")} onClick={confirmGoHome} icon="pi pi-times" loading={submitting}
                className="p-button-secondary"
                style={{marginRight: '.25em', float: "right"}}/>
        )}
        {props.showClear && (
            <Button type="reset" label={_t("Clear")} onClick={confirmClearFields} loading={submitting} icon="pi pi-refresh"
                className="p-button p-button-warning"/>
        )}


    </>);

    const dialogContent = () => {
        if (showMessage) {
            return (
                <>
                    <div className="d-flex ai-center dir-col pt-6 px-3" >
                        <center>
                            <i className="pi pi-check-circle"
                               style={{marginTop: "2rem", fontSize: '5rem', color: 'var(--green-500)'}}></i>
                            <h1>{props.successDialogTitle}</h1>
                            <p style={{lineHeight: 1.5, textAlign: "center"}}>
                                {value && props.successDialogBody(value)}
                            </p>
                        </center>
                    </div>
                </>
            )
        }
        return (<></>)
    }
    const reset = () => {

        setFormReset(!formReset);


    }

    useEffect(() => {
        props.onReset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formReset])

    return (
        <HasPermission mode="ONE" showPanel={true} permissions={props.permissions} panelComponent={<Access/>}>
            <HelpContext.Provider value={{helpState:helpState,helpDispatch:helpDispatch}}>
                <PageAsForm
                    title={props.title} reset={formReset} toolbar={props.toolbar}
                    subtitle={props.subtitle}
                    onChange={props.onChange}
                    onSubmit={onSubmit} schema={props.schema} defaultValues={props.defaults} header={pageFormHeader}
                    footer={footer}
                >
                    <p>{("")}</p>
                    {props.children}
                </PageAsForm>
                <InlineHelp  />
                <div className={"mt-5"}> </div>
                <Dialog
                    visible={showMessage} onHide={() => setShowMessage(false)} position="center" footer={dialogFooter}
                    showHeader={false} breakpoints={{'960px': '80vw'}} style={{width: '80vw'}}
                >
                    {dialogContent()}
                </Dialog>
            </HelpContext.Provider>
        </HasPermission>
    )
};

FXFormPage.propTypes = {
    endpoint: PropTypes.string.isRequired,
    formMethod: PropTypes.oneOf(["POST","PUT"]),
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    toolbar: PropTypes.any,
    schema: PropTypes.object.isRequired,
    defaults: PropTypes.object,
    gotoHomeMessage: PropTypes.string.isRequired,
    successDialogTitle: PropTypes.string.isRequired,
    successDialogBody: PropTypes.func,
    beforeSend: PropTypes.func,
    requestHeader: PropTypes.object,
    confirmBeforeSend: PropTypes.bool,
    errorResponse: PropTypes.func,
    confirmBeforeSendMessage: PropTypes.func,
    onReset: PropTypes.func,
    scroll: PropTypes.bool.isRequired,
    additionalFooter: PropTypes.any,
    saveLabel: PropTypes.any,
    returnPage: PropTypes.string,
    permissions: PropTypes.array,
    showClear: PropTypes.bool,
    showSubmit: PropTypes.bool,
    showGoBack: PropTypes.bool,
    onChange: PropTypes.func,
    children: PropTypes.node,

    confirmAcceptLabel: PropTypes.any,
    confirmRejectLabel: PropTypes.any,
    pageId: PropTypes.string
};

FXFormPage.defaultProps = {
    showClear: true,
    showSubmit: true,
    showGoBack:true,
    returnPage: "/",
    beforeSend: (data) => {
        return data
    },
    successDialogBody: (data) => {  // eslint-disable-line no-unused-vars
        return <Translate value={"generic.dialog-defaultcreated"}></Translate>
    },
    requestHeader: {},
    errorResponse: () => {
        return {}
    },
    confirmBeforeSend: false,
    confirmBeforeSendMessage: (data) => {  // eslint-disable-line no-unused-vars
        return <Translate value={"generic.dialog-defaultconfirm"}></Translate>
    },
    defaults:{},
    onReset: ()=>{},
    scroll:false,
    permissions: [],
    formMethod:"POST",
}