import React, {useState, useEffect} from "react";
import {
    Input, SimpleDropdownMultipleSelect,
} from "starh-comp-common";
import { Editor } from 'primereact/editor';
import {Panel} from "primereact/panel";
import {useTemplateConfigs} from "../../hooks/useTemplateConfigs";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
// import {Button} from "primereact/button";
import {FXCreateModify} from "../../themes/hya-prime-block/components/layouts/FXCreateModify";
import {InputSwitch} from "primereact/inputswitch";
// import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import axios from "axios";

export const CreateModifyTemplate = () =>{
    const {schemaTemplate} = useTemplateConfigs()
    const [content, setContent] = useState(null);
    const [htmlStatus, setHtmlStatus] = useState(true)
    const [listTenant, setListTenant] = useState();
    const {templateEndpoint,tenantEndpoint} = useDefaultsConfigs();

    useEffect(() => {
        axios.get(tenantEndpoint)
            .then((res) =>
            {
                setListTenant(res.data.content.map(({name,nameUp=name.toUpperCase()}) => ({name,nameUp})))
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    const populateFields = data => {
        setContent(data.contents)
        setHtmlStatus(data.html)
        data.tenantIds = data.tenantIds.map((name, {nameUp = name.toUpperCase()}) => ({name, nameUp}))
        return data;
    }

    const beforeSend = (data) => {
        data.contents = content
        data.html = htmlStatus
        data.tenantIds = data.tenantIds.map(({name}) => (name))
        return data;
    }
    const resetForm = () => {
    }

    const displayError = (data) => {
        let myFunction = () => {
            return data.response?.data?.message
        }
        let status = data.response?.status;
        let objectRes = new Object();
        objectRes[status] = () => myFunction();
        return objectRes
    }

    return (<>
        <FXCreateModify
            labelPrefix="Template Config "
            returnPage="/template-config/manage"
            endpoint={templateEndpoint}
            createPermissions={["MANAGE_NOTIFY_TEMPLATE"]}
            editPermissions={["MANAGE_NOTIFY_TEMPLATE"]}
            createSchema={schemaTemplate}
            editSchema={schemaTemplate}
            resetFunction={resetForm}
            beforeSend={beforeSend}
            pageIdCreate={"TEMPLATE-CREATE"}
            pageIdModify={"TEMPLATE-MODIFY"}
            errorResponse={displayError}
            populateFields={populateFields}
        >


            <Panel header={"Template Data"} toggleable={false}>
                <div className="col-12 sm:col-8 md:col-8 xl:col-12 mb-10">
                    <div className="grid fluid">

                        <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2">
                            <Input type={"text"} withController={true} name="name" label="Name*"/>
                        </div>

                        <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2">
                            <SimpleDropdownMultipleSelect
                                nameField={"name"}
                                withController={true}
                                placeholder={"Select a tenant"}
                                name="tenantIds" label={"Tenant Ids *"}
                                items={listTenant}
                                showClear={true}/>
                        </div>

                        <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2">
                            <div className="mb-2"><label htmlFor="html">{"Html*"}</label></div>
                            <InputSwitch checked={htmlStatus} name="html" onChange={e => setHtmlStatus(e.value)}/>
                        </div>

                        <div className="xl:col-12">
                            <div className="mb-2"><label htmlFor="contents">{"Contents*"}</label></div>
                            <Editor style={{ height: '320px'}} label="Template contents" name="contents"
                                    id={"contents"}
                                    onTextChange={(e) => setContent(e.htmlValue)}
                                    value={content}/>
                            </div>

                    </div>
                </div>
            </Panel>
        </FXCreateModify>
        </>)
}