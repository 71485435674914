import {StyleClass} from "primereact/styleclass";
import {Ripple} from "primereact/ripple";
import {useContext, useRef} from "react";
import {UserContext} from "starh-comp-auth";
import {getGravatar} from "starh-comp-common";
import {Avatar} from "primereact/avatar";
import {useHistory} from "react-router-dom";
import {Menu} from "primereact/menu";
import {confirmDialog} from "primereact/confirmdialog";
import {useGlobalRenders} from "../../../../hooks/useGlobalRenders";

export const TopBar = () => {
    const {user} = useContext(UserContext)
    const gravatar = getGravatar(user.email, 100);
    const history = useHistory()
    const { logoutUser } = useContext(UserContext);

    const menu = useRef(null);
    const sidebarRef = useRef()
    const userMenuRef = useRef()
    const {defaultHref} = useGlobalRenders();

    let items = [
        {
            label: "Account",
            icon: 'pi pi-fw pi-power-off', command:()=>{
                history.push("/profile")
            }
        },
        {
            label: "Logout",
            icon: 'pi pi-fw pi-sign-out', command:()=>{
                confirmLogout();
            }
        }
    ]

    const confirmLogout = () => {
        confirmDialog({
            message: "Are you sure you want to exit?",
            header: "Attention",
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Yes",
            rejectLabel: "No",
            accept: () => logoutUser(),
            reject: () => ({})
        });
    }

    return (
        <div
            className="flex justify-content-between align-items-center pl-5 lg:pr-5 surface-section relative lg:static surface-border"
            style={{height: '60px'}}
        >
            <div className="flex mr-1">
                <StyleClass nodeRef={sidebarRef} selector="#sidebar-wrapper" enterClassName="hidden"
                            enterActiveClassName="fadeinleft" leaveToClassName="hidden"
                            leaveActiveClassName="fadeoutleft" hideOnOutsideClick>
                    <a ref={sidebarRef} href={defaultHref} className="p-ripple cursor-pointer block lg:hidden text-700 mr-3" onClick={(e)=>{e.preventDefault()}}>
                        <i className="pi pi-bars text-4xl"></i>
                        <Ripple/>
                    </a>
                </StyleClass>
            </div>

            <div className="hidden lg:block">
                <StyleClass nodeRef={userMenuRef} selector="@next" enterClassName="hidden" enterActiveClassName="fadein"
                            leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                    <a ref={userMenuRef} href={defaultHref} className="p-ripple cursor-pointer block lg:hidden text-700">
                        <Ripple/>
                    </a>
                </StyleClass>
                <div
                    onClick={(event) => menu.current.toggle(event)}
                    aria-controls="popup_menu"  aria-haspopup
                    className="cursor-pointer p-0 m-0 flex-row hidden lg:flex lg:align-items-center select-none lg:flex-row surface-section lg:border-none right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static p-3 lg:p-2"
                >
                    <Avatar image={gravatar} shape={"circle"} className="mr-3 lg:mr-0" style={{width: '32px', height: '32px'}} />
                    <div className="block ml-2">
                        <div className=" font-medium">{user.fullName}</div>
                        <span className=" font-medium text-sm">{user.email}</span>
                    </div>
                    <Menu model={items} popup ref={menu} id="popup_menu" />
                </div>
            </div>

            <div className="block lg:hidden">
                <div
                    onClick={(event) => menu.current.toggle(event)}
                    aria-controls="popup_menu"  aria-haspopup
                    className="cursor-pointer p-0 m-0 flex-row lg:flex lg:align-items-center select-none lg:flex-row z-1 static p-3 lg:p-2"
                >
                    <Avatar image={gravatar} shape={"circle"} className="mr-3 lg:mr-0" style={{width: '32px', height: '32px'}} />
                    <Menu model={items} popup ref={menu} id="popup_menu" />
                </div>
            </div>
        </div>
    )
}