import * as PropTypes from "prop-types";
import {Form} from "starh-comp-common";
import {Page} from "./Page";

export const PageAsForm = (props) => {
    return (
        <Form onSubmit={props.onSubmit} schema={props.schema} defaultValues={props.defaultValues}
              onChange={props.onChange} values={props.values} reset={props.reset}>
             <Page footer={props.footer} header={props.header}
                   subtitle={props.subtitle} title={props.title} toolbar={props.toolbar} >
                 {props.children}
             </Page>
        </Form>
    )
}

PageAsForm.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    footer: PropTypes.element,
    header: PropTypes.element,
    toolbar: PropTypes.any,

    onSubmit: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired,
    defaultValues: PropTypes.object,
    values: PropTypes.object,
    reset: PropTypes.bool,
};