export const mainMenu = [
    {
        title: "App/Tenant",
        iconClass: 'pi pi-fw pi-cloud',
        child: [
            {
                title: "Manage Tenants",
                iconClass: 'pi pi-cog',
                href: '/tenants',
            },
            {
                title: "Manage Applications",
                iconClass: 'pi pi-cog',
                href: '/tenants/application',
            },
            {
                title: "Mappings App/Tenant",
                iconClass: 'pi pi-cog',
                href: '/tenants/mappings',
            }
        ]
    },
    {
        title: "Menus",
        iconClass: 'pi pi-fw pi-link',
        href: '/menu',
    },
    {
        title: "Users",
        iconClass: 'pi pi-fw pi-user',
        child: [
            {
                title: "Users manage",
                iconClass: 'pi pi-users',
                href: '/user',
            },{
                title: "Roles manage",
                iconClass: 'pi pi-user-edit',
                href: '/role',
            }
        ]
    },
    {
        title: "Labels",
        iconClass: 'pi pi-fw pi-flag',
        href: '/labels',
    },
    {
        title: "Notification",
        iconClass: 'pi pi-exclamation-triangle',
        child: [
            {
                title: "Notification config",
                iconClass: 'pi-cog',
                href: '/notification/config',
            },{
                title: "Template config",
                iconClass: 'pi-palette',
                href: '/template-config/manage',
            }
        ]
    },
    {
        title: "Log Mail",
        iconClass: 'pi pi-fw pi-book',
        href: '/log/mail',
    },
];
