
export default function FormatDate(date = new Date(), format = "YYYY-mm-dd", locale = window.navigator.userLanguage || window.navigator.language) {
    let dataAggiornata = format;

    // Years
    if(dataAggiornata.includes("YYYY")){
        dataAggiornata = dataAggiornata.replace("YYYY", date.toLocaleDateString(locale, {year: "numeric"}))
    }else if(dataAggiornata.includes("YY")){
        dataAggiornata = dataAggiornata.replace("YYYY", date.toLocaleDateString(locale, {year: "2-digit"}))
    }

    // Days
    if(dataAggiornata.includes("dd")){
        dataAggiornata = dataAggiornata.replace("dd", date.toLocaleDateString(locale, {day: "2-digit"}))
    }

    // Months
    if(dataAggiornata.includes("mm")){
        dataAggiornata = dataAggiornata.replace("mm", date.toLocaleDateString(locale, {month: "2-digit"}))
    }

    // Hours
    if(dataAggiornata.includes("hh")){
        dataAggiornata = dataAggiornata.replace("hh", date.toLocaleDateString(locale, {hour: "2-digit"}))
    }

    // Minutes
    if(dataAggiornata.includes("ii")){
        dataAggiornata = dataAggiornata.replace("ii", date.toLocaleDateString(locale, {minute: "2-digit"}))
    }

    // Seconds
    if(dataAggiornata.includes("ss")){
        dataAggiornata = dataAggiornata.replace("ss", date.toLocaleDateString(locale, {minute: "2-digit"}))
    }

    return dataAggiornata
}