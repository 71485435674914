import React from "react";
import * as PropTypes from "prop-types";
import {Panel} from "primereact/panel";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import {TemplateListTable} from "../../components/TemplateListTable";

export const TemplateData = (props) => {

    const {
        booleanGridRendered,
        joinArrayToStringGridRendered,
    } = useGlobalRenders();


    const generalData = [
        {'label': ('Tenant Ids'),'value': joinArrayToStringGridRendered(props.data?.tenantIds)},
        {'label': ('Html'),'value': booleanGridRendered(props.data?.html) },
    ]
    return (<>
        {generalData && <>
            <Panel header={'Template general data'} toggleable={false}>
              <TemplateListTable data={generalData} enableTooltip={false} minWidth={'238px'} keyPrefix="generaldata"/>
            </Panel>

        </>}
    </>)

}

TemplateData.propTypes = {
    data: PropTypes.object.isRequired,
}