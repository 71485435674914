import {useTableFilter, useValidation} from "starh-comp-common";
import * as yup from "yup"


export const useMenuItemsConfigs = () => {
    const {
        stringRequired, string, arrayOfObjectRequired, number
    } = useValidation()

    const schema = yup.object().shape({
        id: string,
        menuId: stringRequired,
        label: stringRequired,
        tooltip: string,
        url: string,
        icon: string,
        style: string,
        parentId: string,
        order: number,
        //redirect: yup.bool()
    });

    return {
        schema
    }
}