import {useTableFilter} from "starh-comp-common";
import {useGlobalRenders} from "./useGlobalRenders";




export const useMailLogConfig = () => {
    const { shortcuts } = useTableFilter();
    const {booleanGridRendered} = useGlobalRenders()

    const mailStatus = (data) => {
        return data ? <i className="pi pi-check" style={{ color: 'green',fontSize: '1.5rem' }}></i> : <i className="pi pi-times" style={{ color: 'red',fontSize: '1.5rem' }}></i>

    }


    const fullColumns = [
        {
            header: "Sender",
            field: 'sender',
            sortable: true,
        },
        {
            header: "Config",
            field: 'config',
            sortable: true,
        },
        {
            header: "Template",
            field: 'template',
            sortable: true,
        },
        {
            header: "Sent Date",
            field: 'sentDate',
            sortable: true,

        },
        {
            header: "Email sent?",
            field: 'emailIsSent',
            sortable: true,
            renderer: (rowData) => mailStatus(rowData?.emailIsSent)
        },

    ]
    const fullTextSearchFields = ['sender', 'config']


    const advancedSearchFields = [
        shortcuts.Text("Sender", 'sender'),
        shortcuts.Text("Config", 'config'),
        shortcuts.Text("Template", 'template'),
        shortcuts.Bool("Email is Sent", 'emailIsSent')
    ]


    const exportFields = [
        {
            header: "Sender",
            field: 'sender',
        },
        {
            header: "Config",
            field: 'config',
        },
        {
            header: "Template",
            field: 'template',
        },
        {
            header: "Sent Date",
            field: 'sentDate',
        },
        {
            header: "Log Message",
            field: 'logMessage',
        },
        {
            header: "Email is Sent",
            field: 'emailIsSent',
        },

    ]

    return {
        fullColumns, exportFields, advancedSearchFields, fullTextSearchFields,mailStatus
    }

}
