import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import {HasPermission, Loading} from "starh-comp-auth";
import PropTypes from "prop-types";
import {TemplateData} from "./TemplateData";
import {DisplayContentTemplate} from "./DisplayContentTemplate";

export const DetailsTemplate = (props) => {
    const messages = useRef();
    const {templateEndpoint} = useDefaultsConfigs();
    const [loading, setLoading] = useState(true);
    const [templateData, setTemplateData] = useState(null);

    useEffect(() => {
        axios.get(templateEndpoint + "/" + props.id)
            .then((res) => {
                setTemplateData(res.data)
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                messages?.current?.show({
                    severity: 'error',
                    summary: ("Message"),
                    detail: ("No template found"),
                    sticky: true,
                    closable: false
                });
            }).finally(() => {
            setLoading(false)
        })
    }, [props.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        {<>
             <HasPermission mode="ONE" permissions={["MANAGE_NOTIFY_TEMPLATE"]}>
                {loading && <Loading/>}
                 {templateData && (<>
                 <div className="mb-2">
                     <DisplayContentTemplate data={templateData}/>
                 </div>
                <div className="mb-2">
                    <TemplateData data={templateData}/>
                </div>
                 </>)}
            </HasPermission>
        </>}
    </>)
}

DetailsTemplate.propTypes = {
    location: PropTypes.object,
    id: PropTypes.string,
    backButtonLabel: PropTypes.string
}