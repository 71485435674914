import React, {useRef, useState} from 'react';
import {AjaxTable, DatePicker, Form, Input, Switch} from "starh-comp-common";
import {useTenantsConfigs} from "../../hooks/useTenantsConfigs";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import {Dialog} from "primereact/dialog";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import {HasPermission} from "starh-comp-auth";
import {Page} from "../../themes/hya-prime-block/components/layouts/Page";
import FormatDate from "../../utils/FormatDate";
import {Editor} from "primereact/editor";

export const ManageTenants = () => {
    const {schema, fullColumns, fullTextSearchFields, advancedSearchFields} = useTenantsConfigs()
    const {gridEditButton, gridDeleteButton, defaultPageHeader, defaultDialogFooter} = useGlobalRenders();
    const {tenantEndpoint, defaultDeleteItem, defaultAddEditItem, loadEditItem, arrayToDate} = useDefaultsConfigs();

    const [showDialog, setShowDialog] = useState(false);
    const [regexHelpText, setRegexHelpText] = useState(null);
    const [currentData, setCurrentData] = useState({});
    const [dialogMode, setDialogMode] = useState(null);
    const [formReset, setFormReset] = useState(null);
    const tableRef = useRef();

    const entity = 'tenant';
    const baseEndpoint = tenantEndpoint;
    const reset = () => { setFormReset(!formReset) }
    const loadData = () => {
        if (tableRef.current) {
            tableRef.current.reload();
        }
    }
    const opItem = (op, data) => {
        setDialogMode(op);
        if(data.name) {
            loadEditItem(baseEndpoint, data, data.name, (dataItem) => {
                dataItem.endDate = dataItem.endDate ? arrayToDate(dataItem.endDate) : null;
                setRegexHelpText(data.regexHelpText || '');
                setCurrentData(dataItem);
                setShowDialog(true);
            })
        } else{
            setCurrentData(data);
            setShowDialog(true);
        }
    }
    const pageHeader = () => defaultPageHeader("Add " + entity, () => opItem('ADD', {}))
    const dialogFooter = () => defaultDialogFooter(dialogMode, () => reset())

    fullColumns.push({
        header: "Actions",
        headerStyle: {width: "105px"},
        field: 'actions',
        sortable: false,
        renderer: (data) => {
            return <>
                {gridEditButton(() => opItem('EDIT', data), [])}
                {gridDeleteButton( () => {
                    defaultDeleteItem(baseEndpoint, data, entity, data.name, () => loadData())
                }, [])}
            </>
        }
    })

    const closeDialog = () => {
        setShowDialog(false)
        setRegexHelpText(null)
    }

    const submitForm = (data) => {
        data.admin = data.admin || false;
        data.enabled = data.enabled || false;
        data.endDate = data.endDate ? FormatDate(data.endDate, "YYYY-mm-dd") : null;
        data.regexHelpText = regexHelpText
        defaultAddEditItem(baseEndpoint, data, dialogMode, entity, data.name, () => {
            closeDialog()
            loadData();
        })
    }

    const dialogContent = () => {
        return (<>
            <div className="mb-2"><Input withController={true} name={'name'} label={"Name *"} readonly={dialogMode === 'EDIT'} /></div>
            <div className="mb-2"><Input withController={true} name={'description'} label="Description" /></div>
            <div className="mb-2"><Switch withController={true} name={'admin'} label="Admin" /></div>
            <div className="mb-2"><Switch withController={true} name={'enabled'} label="Enabled" /></div>
            <div className="mb-2"><DatePicker withController={true} name={'endDate'} label="End Date" /></div>
            <div className="mb-2"><Input withController={true} name={'regex'} label="Regex *"/></div>
            <div className="mb-2">
                <div className="mb-2"><label htmlFor="regexHelpText">Regex Help Text</label></div>
                <Editor
                    style={{ height: '19vh'}} name="regexHelpText" value={regexHelpText}
                    id={"regexHelpText"} onTextChange={(e) => setRegexHelpText(e.htmlValue)}
                />
            </div>
        </>)
    }

    return (<>
        <HasPermission permissions={[]}>
            <Page title={"Manage Tenant"} header={pageHeader()}>
                <AjaxTable
                    innerRef={tableRef}
                    endpoint={baseEndpoint}
                    columns={fullColumns}
                    //exportFields={exportFields}
                    fullTextSearchFields={fullTextSearchFields}
                    advancedSearchFields={advancedSearchFields}
                    searchButtonLabel="Search"
                    resetButtonLabel="Reset"
                />

                <Form onSubmit={submitForm} schema={schema} defaultValues={currentData} reset={formReset}>
                    <Dialog visible={showDialog} style={{ width: '50vw' }} header={dialogMode + " " + entity} modal className="p-fluid"  appendTo="self"
                            onHide={()=>{closeDialog()}} footer={dialogFooter()} >
                        {dialogContent()}
                    </Dialog>
                </Form>
            </Page>
        </HasPermission>
    </>)
}
