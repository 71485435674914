import React, {useContext, useRef, useState} from 'react';
import {Button} from "primereact/button";
import {AjaxTable} from "starh-comp-common";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import {Dialog} from "primereact/dialog";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import {hasOnePermission, HasPermission, UserContext} from "starh-comp-auth";
import {Page} from "../../themes/hya-prime-block/components/layouts/Page";
import {confirmDialog} from "primereact/confirmdialog";
import {useNotificationConfigConfig} from "../../hooks/useNotificationConfigConfig";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {UtilsContext} from "../../themes/hya-prime-block/components/UtilsProvider";
import {NotificationConfigDetails} from "./NotificationConfigDetails";
import {PopupMenu} from "../../components/PopupMenu";

export const ManageNotificationConfig = () => {
    const {fullColumns, exportFields, fullTextSearchFields, advancedSearchFields} = useNotificationConfigConfig()
    const {defaultPageHeader} = useGlobalRenders();
    const {showWarnMessage, showInfoMessage} = useContext(UtilsContext);
    const [loading, setLoading] = useState(false)
    const {user} = useContext(UserContext);
    const {notificationConfigEndpoint} = useDefaultsConfigs();
    const history = useHistory()
    const [head, setHead] = useState()
    const [currentData, setCurrentData] = useState();
    const [detailsDialogVisible, setDetailsDialogVisible] = useState(false)
    const tableRef = useRef();
    const entity = 'notification config';
    const baseEndpoint = notificationConfigEndpoint;

    const openPageDetails = (data) => {
        setHead(data?.name)
        setCurrentData(data)
        setDetailsDialogVisible(true)
    }

    const testCred = (data) => {
        setLoading(true);
        axios.get(baseEndpoint + "/test/" + data.id)
            .then((res) => {
                showInfoMessage("Test Connection", "Valid connection!", 3000, false)
            })
            .catch(err => {
                showWarnMessage(("Test Connection"), err.response?.data?.message, 5000, false);
            }).finally(()=>setLoading(false))

    }

    const openPageModify = (data) => {
        history.push({
            pathname: "/notification/config/" + data.id
        })
    }

    const deleteConfig = (data) => {
        confirmDialog({
            message: ("Are you sure you want to delete this configuration?"),
            header: ("Delete configuration"),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: ("Yes"),
            rejectLabel: ("No"),
            accept() {
                axios.delete(baseEndpoint + "/" + data.id)
                    .then((res) => {
                        showInfoMessage("Info", "Config deleted succesfully!", 3000, false)
                    })
                    .catch(err => {
                        let errMex;
                        switch (err.response?.data?.status) {
                            case "BAD_REQUEST":
                                errMex = ("Generic error");
                                break;
                            case "NOT_FOUND":
                                errMex = ("Mail Config not found", {name: data.name});
                                break;
                            case "CONFLICT":
                                errMex = ("Conflict");
                                break;
                            default:
                                errMex = ("Generic error");
                                break;
                        }
                        showWarnMessage(("Error deleting the config"), errMex, 5000, false);
                    }).finally(() => tableRef?.current.reload());
            },
            reject() {
                // foo
            }
        });

    }

    const footerDialogDetails = () => (
        <Button
            label={("Close")} type={"button"} className="p-button-text"
            icon="pi pi-times" onClick={() => setDetailsDialogVisible(false)} style={{float: "left"}}
        />
    )

    const opItem = () => {
        history.push("/notification/config/create")
    }

    const pageHeader = () => defaultPageHeader("Add " + entity, () => opItem('ADD', {}))

    fullColumns.push({
        header: ("Actions"),
        headerStyle: {width: "70px"},
        field: "id",
        renderer: (data) => {
            let menuItems = [];

            hasOnePermission(user, ["MANAGE_NOTIFY_CONFIG"]) &&
            menuItems.push({
                label: ("Details"), icon: 'pi pi-fw pi-eye', command: () => {
                    openPageDetails(data)
                }
            })
            hasOnePermission(user, ["MANAGE_NOTIFY_CONFIG"]) &&
            menuItems.push({
                label: ("Modify"), icon: 'pi pi-fw pi-pencil', command: () => {
                    openPageModify(data)
                }
            })
            hasOnePermission(user, ["MANAGE_NOTIFY_CONFIG"]) &&
            menuItems.push({
                label: ("Delete"), icon: 'pi pi-fw pi-trash', command: () => {
                    deleteConfig(data)
                }
            })
            hasOnePermission(user, ["MANAGE_NOTIFY_CONFIG"]) &&
            menuItems.push({
                label: ("Test Connection"), icon: 'pi pi-fw pi-cog', command: () => {
                    testCred(data)
                }
            })

            return (<>
                <div className="text-center">
                    <PopupMenu items={menuItems}/>
                </div>
            </>)
        }
    })

    return (<>
        <HasPermission permissions={["MANAGE_NOTIFY_CONFIG"]}>
            <Page title={"Manage Notification"} header={pageHeader()}>
                <AjaxTable
                    loading={loading}
                    innerRef={tableRef}
                    endpoint={baseEndpoint}
                    columns={fullColumns}
                    exportFields={exportFields}
                    fullTextSearchFields={fullTextSearchFields}
                    advancedSearchFields={advancedSearchFields}
                    searchButtonLabel="Search"
                    resetButtonLabel="Reset"
                />
            </Page>

            <Dialog
                header={"Details: " + head} footer={footerDialogDetails} onHide={() => setDetailsDialogVisible(false)}
                visible={detailsDialogVisible} closeOnEscape={true} modal style={{width: '80vw', height: '65vh'}}
            >
                {currentData && <NotificationConfigDetails data={currentData}  />}
            </Dialog>
        </HasPermission>
    </>)
}
