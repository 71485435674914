import {BrowserRouter, Switch} from "react-router-dom";
import {Auth, PrivateRoute} from "starh-comp-auth";

import {UtilsProvider} from "./themes/hya-prime-block/components/UtilsProvider";
import Template from "./themes/hya-prime-block/Template";
import PageNotFound from "./themes/hya-prime-block/components/PageNotFound";
import {publicRoutes, routes} from "./routes";

function App() {
    return (
        <BrowserRouter>
            <Auth publicRoutes={publicRoutes} locale={"en"} defaultLocale={"en"} >
                <UtilsProvider>
                    <Template>
                        <Switch>
                            {routes.map((route, index) => {
                                return (
                                    <PrivateRoute key={`route_private_${index}`} path={route.path} {...(route.exact && {exact: true})} component={route.component} />
                                );
                            })}
                            <PrivateRoute key={"not-found"} path="/" component={PageNotFound}/>
                        </Switch>
                    </Template>
                </UtilsProvider>
            </Auth>
        </BrowserRouter>
    );
}

export default App;