import {useTableFilter, useValidation} from "starh-comp-common";
import * as yup from "yup";
import {useGlobalRenders} from "./useGlobalRenders";
import {useDefaultsConfigs} from "./useDefaultsConfigs";


export const useRoleConfigs = () => {
    const { shortcuts } = useTableFilter();
    const {
        string, stringRequired, numberRequired
    } = useValidation()

    const schema = yup.object().shape({
        name: stringRequired,
        description: string,
        order: numberRequired,
        applicationId: stringRequired
    });

    const fullColumns = [
        {
            header: "Name",
            field: 'name',
            sortable: true
        },
        {
            header: "Description",
            field: 'description',
            sortable: true
        },
        {
            header: "Order",
            field: 'order',
            sortable: true
        },
        {
            header: "Permissions",
            field: 'permissions',
            sortable: true,
            renderer: (rowData) => {
                return (
                    <span key={rowData.permissions?.name}>{rowData.permissions?.length} Permissions</span>
                );
            }
        }
    ]
    const fullTextSearchFields = ['name', 'description']

    const advancedSearchFields = [
        shortcuts.Text("Name", 'name'),
        shortcuts.Text("Description", 'description'),
    ]

    const exportFields = [
        {
            header: "Name",
            field: 'name'
        },
        {
            header: "Description",
            field: 'description'
        }
    ]

    return {
        schema, fullColumns, exportFields, advancedSearchFields, fullTextSearchFields
    }
}