import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    AjaxTable,
    useTableFilter
} from "starh-comp-common";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import {Page} from "../../themes/hya-prime-block/components/layouts/Page";
import {HasPermission} from "starh-comp-auth";
import {SelectApplicationTenant} from "../../components/SelectApplicationTenant";
import {useUserConfigs} from "../../hooks/useUserConfigs";
import {OverlayPanel} from "primereact/overlaypanel";
import {Button} from "primereact/button";
import axios from "axios";
import {UtilsContext} from "../../themes/hya-prime-block/components/UtilsProvider";
import {CreateModifyUser} from "./CreateModifyUser";

export const ManageUsers = () => {
    const {
        schema, resetPwdSchema, fullColumns, fullTextSearchFields, advancedSearchFields
    } = useUserConfigs()
    const {
        gridEditButton, gridDeleteButton, defaultPageHeader, listItemsGridRendered,
        smallButtonStyle
    } = useGlobalRenders();
    const {
        userEndpoint, roleEndpoint, roleByTenantEndpoint, defaultDeleteItem,
    } = useDefaultsConfigs();
    const { shortcuts } = useTableFilter();
    const {showWarnMessage, showSuccessMessage} = useContext(UtilsContext);

    const [tenant, setTenant] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [dialogMode, setDialogMode] = useState(null);
    const [roles, setRoles] = useState([]);
    const [currentSchema, setCurrentSchema] = useState({})
    const [allRoles, setAllRoles] = useState([]);

    const rolePanel = useRef(null);
    const tableRef = useRef();

    const entity = 'user';
    const baseEndpoint = userEndpoint;

    const loadData = () => {
        if (tableRef.current) {
            tableRef.current.reload();
        }
    }

    const opItem = (op, data) => {
        data.tenantId = tenant?.name;

        setDialogMode(op);
            setCurrentData(data);
        if(op === 'EDIT' || op === 'ADD') {
            setCurrentSchema(schema)
            setShowDialog(true);
        }else if(op === 'PWD') {
            setCurrentSchema(resetPwdSchema)
            setShowDialog(true);
        }
    }

    const pageHeader = () => {
        return (<>
            {tenant && defaultPageHeader("Add " + entity, () => opItem('ADD', {})) }
        </>)
    }

    useEffect(()=>{
        loadData();
        loadAllRoles();
    }, [tenant])

    const showItem = (e, data) => {
        if(data?.roles && data.roles[0] != null) {
            setRoles(allRoles.filter(p => {
                return data.roles.some((e)=>{return e.id === p.id})
            }));
        }else{
            setRoles(undefined)
        }
        rolePanel.current.toggle(e)
    }

    fullColumns.push({
        header: "Roles",
        headerStyle: {width: "155px"},
        field: "roles",
        renderer: (data) => {
            return <>{listItemsGridRendered(data, "Show roles", data.roles.length + " Roles", showItem)}</>
        }
    })
    fullColumns.push({
        header: "Actions",
        headerStyle: {width: "155px"},
        renderer: (data) => {
            return <>
                {gridEditButton(() => opItem('EDIT', data), [])}
                {
                    <HasPermission mode={"ONE"} permissions={['PASSWORD_MANAGE']} showPanel={false}>
                        <Button icon="pi pi-key" title={"Change Password"} onClick={() => opItem('PWD', data)}
                                tooltipOptions={{position: 'bottom'}} data-pr-tooltip={"Change Password"}
                                className="p-button-help p-button-icon-only p-button-rounded p-button-sm" style={smallButtonStyle}/>
                    </HasPermission>
                }
                {gridDeleteButton( () => {
                    defaultDeleteItem(baseEndpoint, data, entity, data.id, () => loadData())
                }, [])}
            </>
        }
    })

    advancedSearchFields.push(
        shortcuts.SimpleMultipleAjaxListAsObjectId(
            "Roles",
            'roles',
            roleEndpoint,
            'name',
            "Select roles",
        ).setRequestHeaders({
            //applicationId: application?.code,
            tenantId: tenant?.name
        })
    );

    const  loadAllRoles = () =>{
        axios.get(roleByTenantEndpoint)
            .then((res) =>{
                let values = res.data.content;
                values.sort((a, b) => a.name.localeCompare(b.name));
                setAllRoles(values)
            })
            .catch((err)=>{
                console.error(err)
            })
            .finally(()=>{});
    }

    return (<>
        <HasPermission permissions={["USER_MANAGE"]}>
            <Page title={"Manage Users"} header={pageHeader()}>
                <SelectApplicationTenant
                    type={'TEN'} placeholder={'Select Tenant...'}
                    onSelect={(sel) => {
                        setTenant(sel)
                        setCurrentData({ tenantId: sel?.name })

                        axios.defaults.headers['x-tenantid'] = sel.name;
                    }}
                >
                    {(tenant) ? (<>
                        <AjaxTable
                            innerRef={tableRef}
                            endpoint={baseEndpoint + '/roled'}
                            columns={fullColumns}
                            //exportFields={exportFields}
                            fullTextSearchFields={fullTextSearchFields}
                            advancedSearchFields={advancedSearchFields}
                            searchButtonLabel="Search"
                            resetButtonLabel="Reset"
                            requestHeaders={{ ...{'x-tenantid': tenant.name} }}
                        />

                        <OverlayPanel ref={rolePanel} id="overlay_panel" style={{width: '450px'}}>
                            <div>
                                {(roles && roles.length > 0) ? (
                                    <ul>
                                        {roles.map((r) => (
                                            <li key={r.id}><b>{r.name}</b> | <b>{r.applicationId}</b></li>
                                        ))}
                                    </ul>
                                ) : "No role for this user."}
                            </div>
                        </OverlayPanel>

                        {showDialog &&
                            <CreateModifyUser
                                onHide={() => {
                                    tableRef.current.reload();
                                    setShowDialog(false)
                                }}
                                header={"Create/Edit User"}
                                schema={currentSchema}
                                mode={dialogMode}
                                tenantId={tenant.name}
                                dialogMode={dialogMode}
                                roles={allRoles}
                                userId={currentData.id}
                            />
                        }

                    </>) : (<></>)}
                </SelectApplicationTenant>
            </Page>
        </HasPermission>
    </>)
}