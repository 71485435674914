import {Button} from "primereact/button";
import {HasPermission} from "starh-comp-auth";
import React from "react";
import {Chip} from "primereact/chip";


export const useGlobalRenders = () => {

    const defaultLogo = (height = "40", classes = "mr-0 lg:mr-6") => {
        return <img src="/assets/svg/logo.svg" alt="StarH Admin" height={height} className={classes} />
    }
    const defaultHref = window.location.href + '#';

    const smallButtonStyle = {
        margin: '0.1rem',
        padding: "0.1rem",
        width:"2rem",
        height:"2rem"
    }

    const gridDetailButton = (funct,permission=[]) =>
        <HasPermission mode={"ONE"} permissions={permission} showPanel={false}>
            <Button icon="pi pi-book" title={"Details"} onClick={funct}
               tooltipOptions={{position: 'bottom'}} data-pr-tooltip={"Details"}
               className="p-button-success p-button-icon-only p-button-rounded  p-button-sm" style={smallButtonStyle}/>
        </HasPermission>

    const gridEditButton = (funct,permission=[]) =>
        <HasPermission mode={"ONE"} permissions={permission} showPanel={false}>
            <Button icon="pi pi-pencil" title={"Modify"} onClick={funct}
                tooltipOptions={{position: 'bottom'}} data-pr-tooltip={"Modify"}
                className="p-button-primary p-button-icon-only p-button-rounded p-button-sm" style={smallButtonStyle}/>
        </HasPermission>

    const gridDeleteButton = (funct,permission=[]) =>
        <HasPermission mode={"ONE"} permissions={permission} showPanel={false}>
            <Button icon="pi pi-times" title={"Delete"} onClick={funct}
                tooltipOptions={{position: 'bottom'}} data-pr-tooltip={"Delete"}
                className="p-button-danger p-button-icon-only p-button-rounded p-button-sm" style={smallButtonStyle}/>
        </HasPermission>

    const gridTestButton = (funct,permission=[]) =>
        <HasPermission mode={"ONE"} permissions={permission} showPanel={false}>
            <Button icon="pi pi-cog" title={"Test"} onClick={funct}
                    tooltipOptions={{position: 'bottom'}} data-pr-tooltip={"Test"}
                    className="p-button-rounded p-button-secondary p-button-icon-only p-button-sm" style={smallButtonStyle}/>
        </HasPermission>

    const gridExportTemplate = (funct,permission=[]) =>
        <HasPermission mode={"ONE"} permissions={permission} showPanel={false}>
            <Button icon="pi pi-download" title={"Export"} onClick={funct}
                    tooltipOptions={{position: 'bottom'}} data-pr-tooltip={"Export"}
                    className="p-button-rounded p-button-secondary p-button-icon-only p-button-sm" style={smallButtonStyle}/>
        </HasPermission>

    const tripleteGridButtons = (detailFunct, editFunct, deleteFunct,permissionDetail=[],permissionEdit=[],permissionDelete=[]) => (
        <>
            {gridDetailButton(detailFunct,permissionDetail)}
            {gridEditButton(editFunct,permissionEdit)}
            {gridDeleteButton(deleteFunct,permissionDelete)}
        </>
    )

    const FourGridButton = (detailFunct, editFunct, deleteFunct, testFunct,permissionDetail=[],permissionEdit=[],permissionDelete=[], permissionTest=[]) => (
        <>
            {gridDetailButton(detailFunct,permissionDetail)}
            {gridEditButton(editFunct,permissionEdit)}
            {gridDeleteButton(deleteFunct,permissionDelete)}
            {gridTestButton(testFunct,permissionTest)}
        </>
    )

    const FourGridButtonTemplate = (detailFunct, editFunct, deleteFunct, exportFunct,permissionDetail=[],permissionEdit=[],permissionDelete=[], permissionExport=[]) => (
        <>
            {gridDetailButton(detailFunct,permissionDetail)}
            {gridEditButton(editFunct,permissionEdit)}
            {gridDeleteButton(deleteFunct,permissionDelete)}
            {gridExportTemplate(exportFunct,permissionExport)}
        </>
    )

    const locationGridRendered = (location) => {
        return (<>
          <i>Lat: <b>{location?.lat.toFixed(4)}</b></i><br/>
          <i>Lon: <b>{location?.lon.toFixed(4)}</b></i>
        </>)
    }

    const booleanGridRendered = (value) => {
        return value ? "Yes" : "No"
    }

    const joinArrayToStringGridRendered = (value) => {
        if(value){
            return <ul style={{paddingLeft: '20px'}} className={'m-0'}>{value.map(v => <><li>{v}</li></>)}</ul>
        }
        return <></>
    }

    const listItemsGridRendered = (data, tooltip, title, showItems) => {
        return (<>
            <Button
                onClick={(e) => {showItems(e, data)}}
                tooltip={tooltip} tooltipOptions={{position: 'bottom'}}
                aria-haspopup label={title} title={title} className="p-button-sm p-button-secondary"
            />
        </>)
    }

    const flagRendered = (item, props) => {
        let base =  <><img alt={item?.name} src={"/assets/flags/" + item?.filename}/> {item?.name}</>
        return item ? base : props.placeholder || base;
    }

    const langFlagRender = (lang, style, title, alt) => {
        style = (style) ? style : {height: '16px', border: 'solid 1px #ccc'}
        return <img src={'/assets/flags/' + lang + ".png"} style={style} title={title || lang} alt={alt || lang}></img>
    }

    const printLangLabel = (option, defaultMessage = '') => {
        if(option?.value){
            return (
                <div className="country-item">
                    {langFlagRender(option?.value, {height: '20px', width: '30px', border: 'solid 1px #ccc'})} {option?.value.toUpperCase()}
                </div>
            );
        }else{
            return (<>{defaultMessage}</>);
        }
    }

    const defaultPageHeader = (title, clickFunction) => {
        return (<>
            <Button
                icon="pi pi-plus-circle"
                className="my-2 p-button-success p-button p-component p-button-raised p-button-rounded p-button-sm"
                label={title}
                style={{float: 'right'}}
                onClick={clickFunction}
            />
        </>)
    }

    const defaultDialogFooter = (dialogMode, resetClickFunction) => {
        return (<>
            <div>
                {(dialogMode !== 'EDIT') && (<>
                    <Button type="reset" disabled={dialogMode === 'EDIT'} label={"Reset fields"} icon="pi pi-undo"
                            onClick={resetClickFunction} className="p-button-warn" style={{float: "left"}}
                    /></>
                )}
                <Button type="submit" label={"Save"} icon="pi pi-check" className="p-button-success" autoFocus />
            </div>
        </>)
    }

    return {
        defaultLogo,
        locationGridRendered,
        booleanGridRendered,
        gridDetailButton,
        gridDeleteButton,
        gridEditButton,
        tripleteGridButtons,
        FourGridButton,
        listItemsGridRendered,
        defaultHref,
        joinArrayToStringGridRendered,
        defaultPageHeader,
        defaultDialogFooter,
        flagRendered,
        smallButtonStyle,
        langFlagRender,
        printLangLabel,
        FourGridButtonTemplate
    }
}
