import {useTableFilter, useValidation} from "starh-comp-common";
import * as yup from "yup";
import {useGlobalRenders} from "./useGlobalRenders";
import {useDefaultsConfigs} from "./useDefaultsConfigs";


export const useMenuConfigs = () => {
    const { shortcuts } = useTableFilter();
    const {
        stringRequired, string
    } = useValidation()
    const {joinArrayToStringGridRendered} = useGlobalRenders()
    const {applicationEndpoint, tenantEndpoint} = useDefaultsConfigs();

    const schema = yup.object().shape({
        name: stringRequired,
        description: string
    });

    const fullColumns = [
        {
            header: "Name",
            field: 'name',
            sortable: true
        },
        {
            header: "Description",
            field: 'description',
            sortable: true
        },
        {
            header: "Permissions",
            field: 'permissions',
            sortable: true,
            renderer: (rowData) => {
                return (
                    <span key={rowData.permissions?.name}>{rowData.permissions?.length} Permissions</span>
                );
            }
        }
    ]
    const fullTextSearchFields = ['name', 'description']

    const advancedSearchFields = [
        shortcuts.Text("Name", 'name'),
        shortcuts.Text("Description", 'description')
    ]

    const exportFields = [
        {
            header: "Name",
            field: 'name',
        },
        {
            header: "Description",
            field: 'description',
        }
    ]

    return {
        schema,fullColumns, exportFields, advancedSearchFields, fullTextSearchFields
    }

}