import * as yup from "yup";
import {useTableFilter, useValidation} from "starh-comp-common";
import {useGlobalRenders} from "./useGlobalRenders";

export const useTemplateConfigs = () => {
    const {string, date, number, numberRequired, objectRequired, stringRequiredMultipleSpace, stringRequired,numericString, numericStringRequired, year, boolean, listRequired} = useValidation()
    const {joinArrayToStringGridRendered, booleanGridRendered} = useGlobalRenders()
    const { shortcuts } = useTableFilter();

    const schemaTemplate = yup.object().shape({
        name: yup.string().required(),
        contents: yup.string(),
        tenantIds: yup.array().of(yup.object()).required(),
    });

    const templateColumn = [
      {
        header: "Name",
        field: "name",
      },
      {
          header: "Tenants Ids",
          field: "tenantIds",
          renderer: (data) => {
              return joinArrayToStringGridRendered(data.tenantIds)
          }
      },
      {
          header: "Html",
          field: "html",
          renderer: (data) => {
              return booleanGridRendered(data.html)
          }
      }
  ]

    const fullTextSearchFields = ['name']

    const advancedSearchFields = [
        shortcuts.Text("Name", 'name'),
        shortcuts.Bool("Html", 'html'),
    ]

    const exportFields = [
        {
            header: "Name",
            field: 'name',
        },
        {
            header: "Tenant Ids",
            field: 'tenantIds',
        },
        {
            header: "Contents",
            field: 'contents',
        },
        {
            header: "Html",
            field: 'html',
        }
    ]
    const importSchema = yup.object().shape({});

    return {
        schemaTemplate, templateColumn, exportFields, advancedSearchFields, fullTextSearchFields, importSchema
    }

}