import { Button } from 'primereact/button';
import { useKeycloak } from '@react-keycloak/web';
import { useCallback } from 'react';
import {Message} from "primereact/message";
import {useTranslations} from "starh-comp-common"
import {NavLink} from "react-router-dom";
import {PublicPage} from "../../components/layouts/PublicPage";


export const Landing = (props) => {
  const { keycloak } = useKeycloak();
  const message = new URLSearchParams(props.location.search).get('message');
  const {_t} = useTranslations();
  const login = useCallback(() => {
    keycloak?.login();
  }, [keycloak]);

  return (
      <PublicPage
          title={"Admin Manage"}
          subtitle={"Log-in and manage all the applications"}
      >
        <div className="center">
          <Button
              label="Sign In"
              onClick={login}
              type="button"
              className="lg:mr-3 p-button-raised lg:block p-button-lg"
          />
        </div>
        <div className="mt-3 text-2xl">
          <NavLink to="/account/password-reset/init">Forgot your Password?</NavLink>
        </div>
        {message && (
            <Message severity="warn"  style={{fontSize: "1em"}} className="mt-5 block text-center" content={_t(message)} />
        )}
      </PublicPage>
  );
};
