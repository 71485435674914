import React from 'react';
import ReactDOM from 'react-dom';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './index.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';
import PrimeReact from "primereact/api";


PrimeReact.ripple = true;
PrimeReact.autoZIndex = true;
PrimeReact.zIndex = {
	modal: 100001,    // dialog, sidebar
	overlay: 100002,  // dropdown, overlaypanel
	menu: 100005,     // overlay menus
	tooltip: 1100 ,  // tooltip
	toast: 100010     // toast
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
