import React from "react";
import * as PropTypes from "prop-types";
import PanelDataTable from "../../components/PanelDataTable";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import MailConfigDataTable from "../../components/MailConfigDataTable";

export const NotificationConfigDetails = (props) => {

    const {booleanGridRendered,joinArrayToStringGridRendered} = useGlobalRenders()
    const data = [
        {'label': ("name"), 'value': props.data?.name},
        {'label': ("username"), 'value': props.data?.username},
        {'label': ("password"), 'value': props.data?.password},
        {'label': ("host"), 'value': props.data?.host},
        {'label': ("port"), 'value': props.data?.port},
        {'label': ("protocol"), 'value': props.data?.protocol},
        {'label': ("default Encoding"), 'value': props.data?.defaultEncoding},
        {'label': ("sender"), 'value': props.data?.sender},
        {'label': ("start tls"), 'value': booleanGridRendered(props.data?.starttls)},
        {'label': ("default config"), 'value': booleanGridRendered(props.data?.defaultConfig)},
        {'label': ("tenant Ids"), 'value': joinArrayToStringGridRendered(props.data?.tenantIds)},
    ]

    return (<>
        {props.data == null && (<p>Error!</p>) || (<>
            <div className="grid fluid">
                <div className="col-12">
                    <MailConfigDataTable data={data} enableTooltip={false} keyPrefix="configDetails"/>
                </div>
            </div>
        </>)}
    </>)
}

NotificationConfigDetails.propTypes = {
    data: PropTypes.object.isRequired
}

