import React, {useRef, useState} from 'react';
import {Button} from "primereact/button";
import {AjaxTable} from "starh-comp-common";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import {Dialog} from "primereact/dialog";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import {HasPermission} from "starh-comp-auth";
import {Page} from "../../themes/hya-prime-block/components/layouts/Page";
import {useMailLogConfig} from "../../hooks/useMailLogConfig";
import {LogMailDetails} from "./LogMailDetails";

export const ManageLogMail = () => {
    const {fullColumns, exportFields, fullTextSearchFields, advancedSearchFields} = useMailLogConfig()
    const {gridDetailButton} = useGlobalRenders();
    const [loading, setLoading] = useState(false)
    const {mailLogEndpoint} = useDefaultsConfigs();
    const [head, setHead] = useState()
    const [currentData, setCurrentData] = useState();
    const [detailsDialogVisible, setDetailsDialogVisible] = useState(false)
    const tableRef = useRef();
    const baseEndpoint = mailLogEndpoint;

    const openPageDetails = (data) => {
        setHead(data?.sender)
        setCurrentData(data)
        setDetailsDialogVisible(true)
    }

    const footerDialogDetails = () => (
        <Button
            label={("Close")} type={"button"} className="p-button-text"
            icon="pi pi-times" onClick={() => setDetailsDialogVisible(false)} style={{float: "left"}}
        />
    )

    fullColumns.push({
        header: ("Actions"),
        headerStyle: {width: "90px"},
        field: "id",
        renderer: (data) => {
            return <>{(gridDetailButton(() => openPageDetails(data)))}</>
        }
    })

    return (<>
        <HasPermission permissions={["LOG_MAIL_MANAGE"]}>
            <Page title={"Log Mail"}>
                <AjaxTable
                    loading={loading}
                    innerRef={tableRef}
                    endpoint={baseEndpoint}
                    columns={fullColumns}
                    exportFields={exportFields}
                    fullTextSearchFields={fullTextSearchFields}
                    advancedSearchFields={advancedSearchFields}
                    searchButtonLabel="Search"
                    resetButtonLabel="Reset"
                />
            </Page>

            <Dialog
                header={"Details: " + head} visible={detailsDialogVisible} closeOnEscape={true}
                modal style={{width: '80vw', height: '65vh'}} footer={footerDialogDetails}
                onHide={() => setDetailsDialogVisible(false)}
            >
                {currentData &&  <LogMailDetails data={currentData}/>}
            </Dialog>
        </HasPermission>
    </>)
}
