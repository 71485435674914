import React from "react";
import * as PropTypes from "prop-types";
import {Panel} from "primereact/panel";
import {TemplateDataTable} from "../../components/TemplateDataTable";
import {Divider} from "primereact/divider";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";

export const DisplayContentTemplate =(props) => {
    const {
        booleanGridRendered
    } = useGlobalRenders();

    const data = [
        {'label': ('Template Contents'), 'value': (props.data?.contents)},
    ]

    return (
        <>
            {props.data == null && (<p>Error!</p>)}
            {props.data != null && (<>
                <Panel header={'Template general info'} toggleable={true} >
                    <div className="col" >
                        <TemplateDataTable data={data} enableTooltip={false} keyPrefix="templateinfo"/>
                    </div>
                </Panel>
                <Divider/>
            </>)}
        </>)
}
DisplayContentTemplate.propTypes = {
    data: PropTypes.object.isRequired
}