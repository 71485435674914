import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    AjaxDropdownMultipleSelect,
    AjaxTable,
    Form,
    HiddenField,
    Input,
    Number, SearchablePickList,
    Switch,
    Textarea
} from "starh-comp-common";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import {Dialog} from "primereact/dialog";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import {Page} from "../../themes/hya-prime-block/components/layouts/Page";
import {HasPermission} from "starh-comp-auth";
import {SelectApplicationTenant} from "../../components/SelectApplicationTenant";
import {useMenuConfigs} from "../../hooks/useMenuConfigs";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {UtilsContext} from "../../themes/hya-prime-block/components/UtilsProvider";

export const ManageMenus = () => {
    const {
        schema, fullColumns, exportFields, fullTextSearchFields, advancedSearchFields
    } = useMenuConfigs()
    const {gridEditButton, gridDeleteButton, gridDetailButton, defaultPageHeader, defaultDialogFooter} = useGlobalRenders();
    const {
        menuEnpoint, defaultDeleteItem, defaultAddEditItem, loadEditItem, permissionEndpoint
    } = useDefaultsConfigs();

    const [application, setApplication] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [dialogMode, setDialogMode] = useState(null);
    const [formReset, setFormReset] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sourcePermissions, setSourcePermissions] = useState([]);
    const [targetPermissions, setTargetPermissions] = useState([]);
    const [allPermissions, setAllPermissions] = useState([]);
    const {showSuccessMessage, showWarnMessage, showInfoMessage} = useContext(UtilsContext);
    const tableRef = useRef();
    const history = useHistory();

    const entity = 'menu';
    const baseEndpoint = menuEnpoint;
    const reset = () => { setFormReset(!formReset) }
    const loadData = () => {
        if (tableRef.current) {
            tableRef.current.reload();
        }
    }

    const opItem = (op, data) => {
        data.applicationId = application?.code;
        axios.defaults.headers['x-appid'] = data.applicationId;

        setDialogMode(op);
        if(data.id) {
            loadEditItem(baseEndpoint, data, data.id, (dataItem) => {
                dataItem.description = (dataItem.description) ? dataItem.description : '';

                let perms = allPermissions.filter(p => dataItem.permissions.includes(p.name));
                dataItem.permissions = perms;
                onPermissionChange(perms)

                setCurrentData(dataItem);
                setShowDialog(true);
            })
        } else{
            setSourcePermissions(allPermissions)
            setTargetPermissions([])
            setCurrentData(data);
            setShowDialog(true);
        }
    }
    const pageHeader = () => {
        return (<>
            {application && defaultPageHeader("Add " + entity, () => opItem('ADD', {})) }
        </>)
    }

    const dialogFooter = () => defaultDialogFooter(dialogMode, () => reset())

    useEffect(()=>{
        loadAllPermissions();
    }, [])

    useEffect(()=>{
        loadData();
    }, [application])

    fullColumns.push({
        header: "Actions",
        headerStyle: {width: "155px"},
        renderer: (data) => {
            return <>
                {gridDetailButton(() => history.push("/menu/" + data.applicationId + '/' + data.name, []))}
                {gridEditButton(() => opItem('EDIT', data), [])}
                {gridDeleteButton( () => {
                    defaultDeleteItem(baseEndpoint, data, entity, data.id, () => loadData())
                }, [])}
            </>
        }
    })

    const submitForm = (data) => {
        data.permissions = Object.keys(data.permissions).map((p) => {
            return data.permissions[p].name;
        })
        defaultAddEditItem(baseEndpoint, data, dialogMode, entity, data.id, () => {
            setShowDialog(false)
            loadData();
        })
    }

    const loadAllPermissions = () =>{
        axios.get(permissionEndpoint)
            .then((res) =>{
                let values = res.data.content;
                values.sort((a, b) => a.name.localeCompare(b.name));
                setAllPermissions(values)
            })
            .catch((err)=>{})
            .finally(()=>{});
    }

    const onPermissionChange = (value) => {
        setTargetPermissions(value);
        const filtered = allPermissions.filter(p => !value.some((e)=>{return e.name === p.name}));
        //console.log([value, filtered])
        setSourcePermissions(filtered)
    }

    const permissionItemTemplate = (item) => {
        return (
            <p><b>{item.name}</b><br/>{item.description}</p>
        );
    }

    const dialogContent = () => {
        return (<>
            <HiddenField name={'id'} withController={true} value={currentData.id} />
            <div className="mb-2">
                <Input withController={true} name={'applicationId'} value={application?.code}
                       readonly={application?.code !== undefined} label="Application ID"/>
            </div>
            <div className="mb-2">
                <Input withController={true} name={'name'} label="Name *"/>
            </div>
            <div className="mb-2">
                <Textarea withController={true} name={'description'} label="Description"/>
            </div>
            <div className="mb-2">
                <SearchablePickList
                    dataKey="name"
                    border={false}
                    withController={true}
                    label={"Permission"}
                    available={"Avaiable"}
                    selected={"Selected"}
                    name={"permissions"}
                    maxResults={2500}
                    nameField={"name"}
                    searchplaceholder={"Search permissions"}
                    itemTemplate={permissionItemTemplate}
                    searchable={true}
                    onChange={onPermissionChange}
                    value={targetPermissions}
                    idProperty={"name"}
                    source={sourcePermissions}
                />
            </div>
        </>)
    }

    return (<>
        <HasPermission permissions={["MENU_MANAGE"]}>
            <Page title={"Mapping Menu"} header={pageHeader()}>

                <SelectApplicationTenant
                    type={'APP'} placeholder={"Select Application..."}
                    onSelect={(sel) => {
                        setApplication(sel)
                        setCurrentData({ applicationId: sel?.code })
                    }}
                >
                    <AjaxTable
                        innerRef={tableRef}
                        endpoint={baseEndpoint}
                        columns={fullColumns}
                        //exportFields={exportFields}
                        fullTextSearchFields={fullTextSearchFields}
                        advancedSearchFields={advancedSearchFields}
                        searchButtonLabel="Search"
                        resetButtonLabel="Reset"
                        requestHeaders={{
                            "x-appid": application?.code
                        }}
                    />

                    <Form onSubmit={submitForm} schema={schema} defaultValues={currentData} reset={formReset}>
                        <Dialog visible={showDialog} style={{ width: '70vw' }}
                                header={dialogMode + " " + entity} modal className="p-fluid"  appendTo="self"
                                onHide={()=>{setShowDialog(false)}} footer={dialogFooter()} >
                            {dialogContent()}
                        </Dialog>
                    </Form>
                </SelectApplicationTenant>
            </Page>
        </HasPermission>
    </>)
}