import React, {useContext, useReducer, useRef, useState} from "react";
import {Button} from "primereact/button";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import {useTemplateConfigs} from "../../hooks/useTemplateConfigs";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {AjaxTable, Form, SingleFileUpload} from "starh-comp-common";
import {Dialog} from "primereact/dialog";
import {UtilsContext} from "../../themes/hya-prime-block/components/UtilsProvider";
import {confirmDialog} from "primereact/confirmdialog";
import {DetailsTemplate} from "./DetailsTemplate";
import {HasPermission, UserContext, hasOnePermission} from "starh-comp-auth";
import {Page} from "../../themes/hya-prime-block/components/layouts/Page";
import {PopupMenu} from "../../components/PopupMenu";

export const ManageTemplates = () => {
    const {
        templateEndpoint,
    } = useDefaultsConfigs();

const {
    templateColumn,
    advancedSearchFields,
    exportFields,
    fullTextSearchFields,
    importSchema
} = useTemplateConfigs();
const {defaultPageHeader} = useGlobalRenders();

const tableRef = useRef();
const {showWarnMessage, showInfoMessage, showErrorMessage, showSuccessMessage} = useContext(UtilsContext);

const history = useHistory();
const [templateId, setTemplateId] = useState(null);
const [detailsDialogVisible, setDetailsDialogVisible] = useState(false);
const [loading, setLoading] = useState(false);
const [header, setHeader] = useState(null);
const [importDialogVisible, setImportDialogVisible] = useState(false);
const {user} = useContext(UserContext);
const [file, setFile] = useState(null);

const entity = 'notification config';

    const importCancel = () => {
        setImportDialogVisible(false);
    }

    const openPageDetails = (data) => {
        setHeader(data?.name)
        setTemplateId(data.id)
        setDetailsDialogVisible(true)
    }

    const openPageModify = (data) => {
        return () => {
            history.push({
                pathname: "/template-config/" + data.id
            })
        }
    }

    const deleteTemplate = (data) => {
        confirmDialog({
            message: ("Are you sure you want to delete this template?"),
            header: ("Delete template"),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: ("Ok"),
            rejectLabel: ("Cancel"),
            accept() {
                axios.delete(templateEndpoint + "/" + data.id)
                    .then((res) => { // eslint-disable-line no-unused-vars
                        showInfoMessage(("Info"), ("Template successfully removed"), 3000, false)
                    })
                    .catch(err => {
                        let errMex;
                        switch (err.response?.data?.status) {
                            case "BAD_REQUEST":
                                errMex = ("Error");
                                break;
                            case "NOT_FOUND":
                                errMex = ("Template not found, It may have already been deleted ");
                                break;
                            case "CONFLICT":
                                errMex = ("Error, there are tenantIds linked to it");
                                break;
                            default:
                                errMex = ("Error");
                                break;
                        }
                        showWarnMessage(("Attention"), errMex, 1000, true);
                    }).finally(() => tableRef?.current.reload());
            },
            reject() {
            }
        });
    }
    const exportCoordinate = (selectedColumn) => {
        var regex = /[&\/\\#,^+()$~%.'":*?<>{}]/g //eslint-disable-line
        let fileName = selectedColumn?.name
        if(selectedColumn) {
            axios({
                url: "/api/notification/template-config/export-json/" + selectedColumn?.id,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName.replace(regex,'') + '.json');
                document.body.appendChild(link);
                link.click();
                showSuccessMessage(("Success"), ("Template succesfully exported"), 5000, false)
            }).catch(() =>{
                showErrorMessage(("Error"), ("Error during the export"), 5000, false)
            })
        }else{
            showErrorMessage(("Error"), ("Impossible to export"), 5000, false)
        }
    };  // eslint-disable-line react-hooks/exhaustive-deps

    const opItem = (op) => {
        if(op === 'IMPO'){
            setImportDialogVisible(true);
        }
        if(op === 'ADD'){
            history.push("/template-config/create")
        }
    }

    const onUpload = (e) => {
        let formData = new FormData()

        if(!file){
            formData.append('file', e)
            setFile(formData)
        }
    }

    const onSubmit = () => {

        setLoading(true)
        axios.post("/api/notification/template-config/import", file)
            .then((res) =>{
                showSuccessMessage(("Success"), "Template imported successfully",10000, false)
            }).catch(err =>
            showErrorMessage(("Error"), err.response?.data?.message, 10000, false)
        )
            .finally(() => {
                setLoading(false)
                setImportDialogVisible(false)
                setFile(false)
                tableRef?.current.reload()
            })
    }


    const pageHeader = () =>{
        return(<>
            {defaultPageHeader("Add " + entity, () => opItem('ADD', {}))}

            <Button
                type={"button"}
                icon="pi pi-cloud-upload"
                className="my-2 p-button-info p-button p-component p-button-raised p-button-rounded p-button-sm"
                label={'Import'}
                style={{float: 'right', marginRight: '5px'}}
                onClick={() => opItem('IMPO', {})}
                loading={loading}
            />
        </>)
    }

    templateColumn.push({
        header: "Actions",
        headerStyle: {width: "70px"},
        field: "id",
        renderer: (data) => {
            let menuItems = [];

            {
                hasOnePermission(user, ["MANAGE_NOTIFY_CONFIG"]) &&
                menuItems.push({
                    label: ("Details"), icon: 'pi pi-fw pi-eye', command: () => {
                        openPageDetails(data)
                    }
                })
            }

            {
                hasOnePermission(user, ["MANAGE_NOTIFY_CONFIG"]) &&
                menuItems.push({
                    label: ("Modify"), icon: 'pi pi-fw pi-pencil', command: openPageModify(data)
                })
            }

            {
                hasOnePermission(user, ["MANAGE_NOTIFY_CONFIG"]) &&
                menuItems.push({
                    label: ("Delete"), icon: 'pi pi-fw pi-trash', command: () => {
                        deleteTemplate(data)
                    }
                })
            }

            {
                hasOnePermission(user, ["MANAGE_NOTIFY_CONFIG"]) &&
                menuItems.push({
                    label: ("Export"), icon: 'pi pi-fw pi-download', command: () => {
                        exportCoordinate(data)
                    }
                })
            }

            return (
                <>
                   <div className="text-center">
                       <PopupMenu items={menuItems}/>
                   </div>
                </>)
        }
    })

    const footerDialogDetails = () => (
        <Button loading={loading} label={("Close")} type={"button"}
                icon="pi pi-times" onClick={() => setDetailsDialogVisible(false)} style={{float: "left"}}
                className="p-button-text"/>
    )
    return (<>
        <HasPermission permissions={["MANAGE_NOTIFY_CONFIG"]}>
            <Page title={"Manage Template"} header={pageHeader()}>
                <AjaxTable
                    innerRef={tableRef}
                    loading={loading}
                    endpoint={templateEndpoint}
                    fullTextSearchFields={fullTextSearchFields}
                    columns={templateColumn}
                    advancedSearchFields={advancedSearchFields}
                    exportFields={exportFields}
                    searchButtonLabel="Search"
                    resetButtonLabel="Reset"
                    />
            </Page>
            <Dialog header={"Details: " + header}
                    visible={detailsDialogVisible} closeOnEscape={true}
                    modal style={{width: '80vw', height: '90vh'}}
                    footer={footerDialogDetails}
                    onHide={() => setDetailsDialogVisible(false)}>
                <DetailsTemplate id={templateId}/>
            </Dialog>
            <Dialog visible={importDialogVisible}
                    closeOnEscape={true}
                    onHide={() => setImportDialogVisible(false)}
            >
                <Form schema={importSchema} onSubmit={onSubmit}>
                    <div className="grid">
                        <div className="col-12 md:col-12 mb-1">
                            <SingleFileUpload
                                label={("Choose a Json file to import")}
                                name="file"
                                withController={true}
                                onFileSelect={(e) => {
                                    onUpload(e)
                                }}
                                acceptedFileTypes={["application/json"]}
                            />
                        </div>

                        <div className="col-6 text-left">
                            <Button label={("Cancel")} type={"button"} onClick={importCancel} icon="pi pi-times" className="p-button-text"/>
                        </div>

                        <div className="col-6 text-right ">
                            <Button loading={loading} label={("Import")} type={"submit"} icon="pi pi-check" autoFocus/>
                        </div>
                    </div>
                </Form>
            </Dialog>
        </HasPermission>
    </>)
}
