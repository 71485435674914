import {useTableFilter, useValidation} from "starh-comp-common";
import * as yup from "yup";
import {useGlobalRenders} from "./useGlobalRenders";
import {useDefaultsConfigs} from "./useDefaultsConfigs";


export const useUserConfigs = () => {
    const { shortcuts } = useTableFilter();
    const {roleEndpoint} = useDefaultsConfigs();
    const {booleanGridRendered} = useGlobalRenders();

    const schema = yup.object().shape({
        // id: yup.string(),
        // tenantId: yup.string().required(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        email: yup.string().required(),
        active: yup.bool(),
        // roles: arrayOfObjectRequired(1),
        accountValidityStart: yup.date().required(),
        accountValidityEnd: yup.date().min(yup.ref('accountValidityStart'))
    })

    const resetPwdSchema = yup.object().shape({
        newPassword: yup.string().min(3).required(),
        confirmPassword: yup.string().min(3).required().oneOf([yup.ref('newPassword'), null])
    })

    const fullColumns = [
        {
            header: "First Name",
            field: 'firstName',
            sortable: true
        },
        {
            header: "Last Name",
            field: 'lastName',
            sortable: true
        },
        {
            header: "E-mail",
            field: 'email',
            sortable: true
        },
        {
            header: "Active",
            field: 'active',
            sortable: true,
            renderer: (rowData) => booleanGridRendered(rowData.active)
        },
        {
            header: "Tenant",
            field: 'tenantId',
            sortable: true
        }
    ]
    const fullTextSearchFields = ['firstName', 'lastName', 'email', 'tenantId']

    const advancedSearchFields = [
        shortcuts.Text("First Name", 'firstName'),
        shortcuts.Text("Last Name", 'lastName'),
        shortcuts.Text("E-mail", 'email'),
        shortcuts.Text("Tenant", 'tenantId')
    ]

    const exportFields = [
        {
            header: "First Name",
            field: 'firstName'
        },
        {
            header: "Last Name",
            field: 'lastName'
        },
        {
            header: "E-mail",
            field: 'email'
        },
        {
            header: "Active",
            field: 'active'
        },
        {
            header: "Tenant",
            field: 'tenantId'
        },
        {
            header: "Roles",
            field: 'roles'
        }
    ]

    return {
        schema, resetPwdSchema, fullColumns, exportFields, advancedSearchFields, fullTextSearchFields
    }

}