import {themeRoutes} from "./themes/hya-prime-block/routes";

import {Home} from "./pages/Home";
import {UserProfile} from "./pages/user/UserProfile";
import {ManageTenants} from "./pages/tenant/ManageTenants";
import {ManageAppTenantMapping} from "./pages/tenant/ManageAppTenantMapping";
import {ManageApplications} from "./pages/tenant/ManageApplications";
import {ManageMenus} from "./pages/menu/ManageMenus";
import {ManageMenuItems} from "./pages/menu/ManageMenuItems";
import {ManageUsers} from "./pages/user/ManageUsers";
import {ManageRoles} from "./pages/user/ManageRoles";
import {ManageLabels} from "./pages/label/ManageLabels";
import {ManageTemplates} from "./pages/templates/ManageTemplates";
import {DetailsTemplate} from "./pages/templates/DetailsTemplate";
import {CreateModifyTemplate} from "./pages/templates/CreateModifyTemplate";
import {ManageNotificationConfig} from "./pages/notification/ManageNotificationConfig";
import {CreateModifyNotificationConfig} from "./pages/notification/CreateModifyNotificationConfig";
import {ManageLogMail} from "./pages/log/ManageLogMail";

export const publicRoutes = themeRoutes;

export const routes = [
    { path: '/', component: Home, exact: true , icon:"pi-home"  },
    { path: '/profile', component: UserProfile, exact: true, icon:"pi-user" },

    {path: '/tenants', component: ManageTenants, exact: true},
    {path: '/tenants/mappings', component: ManageAppTenantMapping, exact: true},
    {path: '/tenants/application', component: ManageApplications, exact: true},
    {path: '/menu', component: ManageMenus, exact: true},
    {path: '/template-config/manage', component: ManageTemplates, exact: true},
    {path: '/template-config/create', component: CreateModifyTemplate, name: "CreateModifyTemplate", exact: true},
    {path: '/template-config/:id', component: CreateModifyTemplate, name: "CreateModifyTemplate", exact: true},
    {path: '/template-config/details/:id', component: DetailsTemplate, name: "DetailsTemplate", exact: true},
    {path: '/menu/:appID/:menuID', component: ManageMenuItems, exact: true},
    {path: '/user', component: ManageUsers, exact: true},
    {path: '/role', component: ManageRoles, exact: true},
    {path: '/labels', component: ManageLabels, exact: true},
    {path: '/notification/config', component: ManageNotificationConfig, exact: true},
    {path: '/notification/config/create', component: CreateModifyNotificationConfig, name: "CreateNotificationConfig", exact: true},
    {path: '/notification/config/:id', component: CreateModifyNotificationConfig, name: "ModifyNotificationConfig", exact: true},
    {path: '/log/mail', component: ManageLogMail, name: "ModifyLogMail", exact: true},


];
