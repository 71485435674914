import {Translate, useTranslations} from "starh-comp-common";
import React, {useContext} from "react";
import {UserContext} from "starh-comp-auth";
import {confirmDialog} from "primereact/confirmdialog";
import axios from "axios";
import {UtilsContext} from "../themes/hya-prime-block/components/UtilsProvider";
import _ from "lodash";


export const useDefaultsConfigs = () => {
    const {user} = useContext(UserContext)
    const {_t} = useTranslations()
    const {showWarnMessage, showSuccessMessage} = useContext(UtilsContext);

    // Censimento Endpoint applicativi
    const notificationConfigEndpoint = "/api/notification/mail-config";
    const mailLogEndpoint = "/api/notification/mail-log";
    const tenantEndpoint = "/gateway/tenant";
    const applicationEndpoint = "/gateway/application";
    const appTenantConfigEndpoint = "/gateway/apptenantconfig";
    const menuEnpoint = "/gateway/menu";
    const menuItemEndpoint = "/gateway/menu-item";
    const templateEndpoint = "/api/notification/template-config";
    const userEndpoint = "/gateway/user";
    const roleEndpoint = "/gateway/role";
    const roleByTenantEndpoint = "/gateway/role/by-tenant";
    const permissionEndpoint = "/gateway/permission";
    const labelEndpoint = "/gateway/label";
    const labelImportEndpoint = "/gateway/label/import";
    const labelExportEndpoint = "/gateway/label/export";

    const userAccountEndpoint = "/gateway/account";
    const resetPassInitEndpoint = "/gateway/public/account/reset-password/init";
    const resetPassFinishEndpoint = "/gateway/public/account/reset-password/finish";

    const baseFormErrorResponse = (text, attribute="name") => {
        let myFunction = (d) => {return _t(text, {name: d[attribute]})}
        return ()=>{
            return {409: myFunction}
        }
    }
    const baseBeforeSend = (data) => {
        data.tenantId = user.tenantId;
        return data;
    }
    const baseSuccessMessage = (text, attribute="name") => {
        return (data)=> {
            return <Translate value={text} data={{name: data[attribute]}}/>
        }
    }

    const arrayToDate = (date) => {
        let d = (date) ? date.split('-') : null
        if (_.isArray(d) && d.length > 0) {
            return new Date(d[1]+'-'+d[2]+'-'+d[0] + ' 01:00:00 GMT');
        }
        return null
    }

    const queryStringEndpoint = (endpoint, value) => {
        let arr = endpoint.split('?');
        let qs = (arr[1]) ? '?' + arr[1] : '';

        if(value){
            return arr[0] + '/' + value + qs
        }
        return arr[0] + qs
    }

    const getNameOrValue = (data, value) => {
        return (data.name) ? data.name : value;
    }

    const loadEditItem = (endpoint, data, value, successFunction, errorFunction) => {
        let ajax = axios.get;
        let ep = queryStringEndpoint(endpoint, value);
        ajax(ep, data)
            .then((r) => {
                successFunction(r.data);
            })
            .catch(e => {
                errorFunction()
                showWarnMessage("Attention", e.response.data.detail || e.response.data.title);
            });
    }

    const defaultAddEditItem = (endpoint, data, dialogMode, entity, value, successFunction = () => {}, errorFunction = () => {}, label) => {
        let ajax = axios.post;
        let ep = endpoint;
        if (dialogMode === "EDIT" && (data.hasOwnProperty('id') || data.hasOwnProperty('name') || data.hasOwnProperty('code'))) {
            ajax = axios.put;
            ep = queryStringEndpoint(endpoint, value)
        }
        ajax(ep, data)
            .then((r) => {
                const name = (label) ? label : getNameOrValue(data, value);
                if (dialogMode === "EDIT") {
                    showSuccessMessage("Info", entity + " '" + name + "' updated successfully.")
                } else {
                    showSuccessMessage("Info", entity + " '" + name + "' added successfully.")
                }
                successFunction();
            })
            .catch(e => {
                console.log(e)
                errorFunction();
                showWarnMessage("Attention", e.response.data.detail || e.response.data.title);
            });
    }

    const defaultDeleteItem = (endpoint, data, entity, value, successFunction, errorFunction, label) => {
        let name = (label || data.name || data.fullName) ? label || data.name || data.fullName : value;
        confirmDialog({
            message: "vuoi rimuovere "+entity+" '" + name + "'?",
            header: "Attenzione",
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Ok",
            rejectLabel: "Cancel",
            accept() {
                axios.delete(queryStringEndpoint(endpoint, value))
                    .then((r) => {
                        successFunction()
                        showSuccessMessage("Info", "Hai cancellato '"+name+"' correttamente");
                    })
                    .catch(e => {
                        errorFunction()
                        showWarnMessage("Attenzione", e.response.data.detail);
                    });
            },
            reject() {
                // FOO
            }
        })
    }

    return {
        tenantEndpoint, applicationEndpoint, appTenantConfigEndpoint, menuEnpoint, menuItemEndpoint,notificationConfigEndpoint,mailLogEndpoint,
        userEndpoint, roleEndpoint, roleByTenantEndpoint, permissionEndpoint, labelEndpoint,
        resetPassInitEndpoint, resetPassFinishEndpoint,
        labelImportEndpoint, labelExportEndpoint, userAccountEndpoint,templateEndpoint,
        baseSuccessMessage,baseBeforeSend, baseFormErrorResponse,
        defaultDeleteItem, defaultAddEditItem, loadEditItem, queryStringEndpoint, getNameOrValue,
        arrayToDate
    }
}
