import {useValidation} from "starh-comp-common";
import * as yup from "yup";
import {Tooltip} from "primereact/tooltip";
import {useGlobalRenders} from "./useGlobalRenders";


export const useLabelConfigs = () => {
    const {
        stringRequired, string, objectRequired, fileRequired
    } = useValidation()
    const {langFlagRender} = useGlobalRenders();

    const importSchema = {
        language: objectRequired,
        //file: fileRequired
    }
    const exportSchema = {
        language: stringRequired
    }
    const defaultSchema = {
        key: stringRequired,
        defaultLang: objectRequired,
        translatedValues: string
    }
    const schema = yup.object().shape({
        key: stringRequired,
        defaultLang: objectRequired,
        translatedValues: string
    });

    const fullColumns = [
        {
            header: "Key",
            field: 'key',
            sortable: true
        },
        {
            header: "Translations",
            field: 'translatedValues',
            renderer: (rowData) => {
                return Object.keys(rowData.translatedValues).map( (lang) => {
                    let tooltip = rowData.translatedValues[lang];
                    return (<>
                        <Tooltip target=".translation" key={'tooltip-'+rowData.id+'-'+lang} mouseTrack mouseTrackLeft={10} />
                        <img key={rowData.id+'-'+lang} className="translation bluegray-tooltip" src={'/assets/flags/'+lang+".png"}
                             key={'img-'+rowData.id+'-'+lang}
                             style={{height: "20px", 'marginRight': '10px', border: 'solid 1px #ccc'}}
                             data-pr-tooltip={tooltip} data-pr-position="top">
                        </img>
                    </>);
                });
            }
        }
    ]
    const fullTextSearchFields = ['key', 'translatedValues']

    const exportFields = [
        {
            header: "Key",
            field: 'key'
        },
        {
            header: "Default Language",
            field: 'defaultLang'
        }
    ]

    return {
        schema, defaultSchema, fullColumns, exportFields, fullTextSearchFields, importSchema, exportSchema
    }
}