import React from 'react'
import PrimeReact from 'primereact/api';

/* THEME CONFIGS */
import MenuTree from "./components/layouts/MenuTree";
import "./theme.scss"

/* COMPONENTS */
import {TopBar} from "./components/layouts/TopBar";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import {NavLink} from "react-router-dom";

const Template = (props) => {
    /* GRAPHICS CONFIGS */
    PrimeReact.ripple = true;

    const {defaultLogo} = useGlobalRenders();
    const logo = defaultLogo(40, "mr-0 lg:mr-1");

    return (
        <div className="min-h-screen flex flex-column surface-ground">
            <div id="topbar-wrapper" className="surface-overlay py-1 px-3 flex align-items-center justify-content-between relative lg:fixed surface-border w-full border-bottom-1 h-4rem z-2">
                <div className="pt-1">
                    <NavLink to={'/'}>{logo}</NavLink>
                    <span style={{fontWeight: 'bold', color: '#1D3B5D'}}>ADMIN</span>
                </div>
                <TopBar />
            </div>

            <div className="flex relative lg:static surface-ground mt-0 lg:mt-7 z-5 lg:z-1">
                {/* SIDEBAR MENU */}
                <div id="sidebar-wrapper" className="hidden lg:block flex-shrink-0 fixed lg:static left-0 top-0 z-2 border-right-none lg:border-right-1 border-gray-800 w-18rem lg:w-7rem select-none">
                    <div className="flex flex-column lg:fixed lg:w-7rem">
                        {/* SIDEBAR MENU */}
                        <div className="mt-0 lg:mt-3 select-none">
                            <ul className="list-none p-1 lg:p-0 m-0">
                                <div className="block lg:hidden px-4 bg-white py-2">{logo}</div>
                                <MenuTree menu={'mainMenu'} />
                            </ul>
                        </div>
                    </div>
                </div>

                {/* CONTENT WRAPPER */}
                <div id="subcontent-wrapper" className="relative w-full">
                    {/* CONTENT */}
                    <div id="content-wrapper" className="flex flex-column flex-auto overflow-x-hidden overflow-y-auto">
                        <div className="surface-section flex-auto p-3">
                            {props.children}
                        </div>
                    </div>
                    <div id="app-footer" className="flex surface-overlay surface-border border-top-1">
                        <div className="py-1 px-3 h-2rem">footer</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Template;