import React, {useEffect, useRef, useState} from 'react';
import {AjaxTable, Chips, Form, HiddenField, Input, SimpleDropdown} from "starh-comp-common";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import {Dialog} from "primereact/dialog";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import {Page} from "../../themes/hya-prime-block/components/layouts/Page";
import {HasPermission} from "starh-comp-auth";
import {useApplicationsConfigs} from "../../hooks/useApplicationsConfigs";

export const ManageApplications = () => {
    const {
        schema,
        fullColumns,
        exportFields,
        fullTextSearchFields,
        advancedSearchFields
    } = useApplicationsConfigs()
    const {gridEditButton, gridDeleteButton, defaultPageHeader, defaultDialogFooter, printLangLabel} = useGlobalRenders();
    const {applicationEndpoint, defaultDeleteItem, defaultAddEditItem, loadEditItem} = useDefaultsConfigs();

    const [showDialog, setShowDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [dialogMode, setDialogMode] = useState(null);
    const [formReset, setFormReset] = useState(null);
    const [currentDefaultLang, setCurrentDefaultLang] = useState({})
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const tableRef = useRef();

    const entity = 'application';
    const baseEndpoint = applicationEndpoint;
    const reset = () => { setFormReset(!formReset) }
    const loadData = () => {
        if (tableRef.current) {
            tableRef.current.reload();
        }
    }
    const opItem = (op, data) => {
        setDialogMode(op);
        setCurrentDefaultLang({})
        setSelectedLanguage({})
        if(data.code) {
            loadEditItem(baseEndpoint, data, data.code, (dataItem) => {
                setCurrentDefaultLang(dataItem.languages.map(l => {
                    return {label: l, value: l}
                }));

                setSelectedLanguage(dataItem.defaultLang)
                setCurrentData(dataItem);
                setShowDialog(true);
            })
        } else{
            setCurrentData(data);
            setShowDialog(true);
        }
    }
    const pageHeader = () => defaultPageHeader("Add " + entity, () => opItem('ADD', {}))
    const dialogFooter = () => defaultDialogFooter(dialogMode, () => reset())

    fullColumns.push({
        header: "Actions",
        headerStyle: {width: "105px"},
        renderer: (data) => {
            return <>
                {gridEditButton(() => opItem('EDIT', data), [])}
                {gridDeleteButton( () => {
                    defaultDeleteItem(baseEndpoint, data, entity, data.code, () => loadData())
                }, [])}
            </>
        }
    })

    const submitForm = (data) => {
        data.defaultLang = (data.defaultLang) ? data.defaultLang : currentDefaultLang[0] || 'en';
        if(dialogMode == 'EDIT' && !data.languages.includes(data.defaultLang)){
            data.languages.push(data.defaultLang)
        }

        defaultAddEditItem(baseEndpoint, data, dialogMode, entity, data.code, () => {
            setShowDialog(false)
            loadData();
        })
    }

    useEffect(()=>{
        // console.log(selectedLanguage)
    },[selectedLanguage])

    const dialogContent = () => {
        return (<>
            <div className="mb-2"><Input withController={true} name={'code'} label={"Code"} readonly={dialogMode === 'EDIT'}></Input></div>
            <div className="mb-2"><Input withController={true} name={'description'} label="Description"></Input></div>

            <div className="mb-2">
                <Chips
                    withController={true}
                    name="languages"
                    label="Languages"
                    allowDuplicate={false}
                    helpLabel={"Write and press ENTER to insert the language"}
                    onChange={(values) => {
                        if(dialogMode == 'ADD'){
                            if(selectedLanguage && selectedLanguage.hasOwnProperty('value') && values.includes(selectedLanguage.value) === false){
                                setSelectedLanguage(null)
                            }
                            setCurrentDefaultLang(values.map(l => {
                                return {label: l, value: l}
                            }))
                        }
                    }}
                />
            </div>

            <div className="mb-2">
                <HiddenField name={"defaultLang"} withController={true} value={selectedLanguage}>
                    <SimpleDropdown
                        withController={false}
                        name="defaultLangField"
                        label="Default Language *"
                        items={currentDefaultLang}
                        nameField={'label'}
                        value={selectedLanguage}
                        placeholder="Select language"
                        onChange={(val)=>{
                            setSelectedLanguage(val)
                        }}
                        readOnly={dialogMode == 'EDIT'}
                        itemTemplate={printLangLabel}
                        valueTemplate={(options) => printLangLabel(options, 'Select Language')}
                    />
                    {(dialogMode == 'EDIT') && <><p>Attenzione! La lingua di default non può essere eliminata!</p></>}
                    {(dialogMode == 'ADD') && <><p>La lingua di default deve essere settata una volta inserite le chips</p></>}
                </HiddenField>
            </div>
        </>)
    }

    return (<>
        <HasPermission permissions={[]}>
            <Page title={"Manage Applications"} header={pageHeader()}>
                <AjaxTable
                    innerRef={tableRef}
                    endpoint={baseEndpoint}
                    columns={fullColumns}
                    //exportFields={exportFields}
                    fullTextSearchFields={fullTextSearchFields}
                    advancedSearchFields={advancedSearchFields}
                    searchButtonLabel="Search"
                    resetButtonLabel="Reset"
                />

                <Form onSubmit={submitForm} schema={schema} defaultValues={currentData} reset={formReset}>
                    <Dialog visible={showDialog} style={{ width: '50vw' }} header={dialogMode + " Application"} modal className="p-fluid"  appendTo="self"
                            onHide={()=>{setShowDialog(false)}} footer={dialogFooter()} >
                        {dialogContent()}
                    </Dialog>
                </Form>
            </Page>
        </HasPermission>
    </>)
}