import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "starh-comp-auth";
import {Input, Form, useValidation} from "starh-comp-common";
import * as yup from "yup";
import axios from "axios";
import {Button} from "primereact/button";
import {UtilsContext} from "../../themes/hya-prime-block/components/UtilsProvider";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";


export const ProfileEdit = () => {
    const [userDTO, setUserDTO] = useState(undefined);
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const {showWarnMessage, showInfoMessage} = useContext(UtilsContext);
    const {user} = useContext(UserContext);
    const {userEndpoint} = useDefaultsConfigs();
    const {
        stringRequiredMultipleSpace,
        string,
        phone,
    } = useValidation()

    useEffect(() => {
        axios.get(userEndpoint + '/' + user.id)
            .then(resp => {
                let data = resp.data
                data = {...data, ...resp.data.meta}
                setUserDTO(data)
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);


    const schema = yup.object().shape({
        firstName: stringRequiredMultipleSpace,
        lastName: stringRequiredMultipleSpace,
        phone: phone,
        mobile: phone,
        address: string,
    });

    const onSubmit = async (data) => {
        userDTO.firstName = data.firstName
        userDTO.lastName = data.lastName
        userDTO.email = data.email
        userDTO.meta.mobile = data.mobile
        userDTO.meta.phone = data.phone
        userDTO.meta.address = data.address
        userDTO.meta.external = data.external
        userDTO.meta.vesselSets = data.vesselSets
        delete userDTO.mobile
        delete userDTO.address
        delete userDTO.phone
        delete userDTO.external
        delete userDTO.vesselSets

        setSubmitting(true);

        axios.put(userEndpoint + '/' + userDTO.id,
            userDTO
        ).then((res) => {
            setSubmitting(false);
            showInfoMessage("Info", "Profile Updated Succesfully!", 5000, false)
        }).catch((err) => {
            setSubmitting(false);
            showWarnMessage("Attention", "Error occured!", 5000, false)
            console.log(err);
        })
    };

    return (<>
        <div id="account-profile-edit">
            {userDTO ? (
                <Form onSubmit={onSubmit} schema={schema} defaultValues={userDTO}>
                    <div className="grid fluid">
                        <div className="col-12 md:col-6">
                            <Input type={'text'} withController={true} name="firstName"
                                   label="First Name"/>
                        </div>
                        <div className="col-12 md:col-6">
                            <Input type={'text'} withController={true} name="lastName" label="Last Name" />
                        </div>
                        <div className="col-12 md:col-6">
                            <Input type={'text'} withController={true} name="email" label="E-mail"
                                   style={{textTransform: 'lowercase'}} readonly={true}/>
                        </div>
                        <div className="col-12 md:col-6">
                            <Input type={'text'} withController={true} name="phone" label="Phone"/>
                        </div>
                        <div className="col-12 md:col-6">
                            <Input type={'text'} withController={true} name="mobile" label="Mobile"/>
                        </div>
                        <div className="col-12 md:col-6">
                            <Input type={'text'} withController={true} name="address" label="Address"/>
                        </div>
                    </div>
                    <Button style={{float:"right"}} className={"p-button-success"} type="submit" label="Update" loading={submitting} icon="pi pi-save"/>
                </Form>
            ) : (<>
                {loading ? (<>
                    <p className={"text-center"}>
                        <i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i>
                        <br/>
                        Loading Profile Infos..
                    </p>
                </>) : (<h1>Error</h1>)}
            </>)}
        </div>
    </>);
}