import React, {useEffect, useRef, useState} from 'react';
import {AjaxTable, Form, Input, Textarea, SearchablePickList, Number} from "starh-comp-common";
import {useGlobalRenders} from "../../hooks/useGlobalRenders";
import {Dialog} from "primereact/dialog";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import {Page} from "../../themes/hya-prime-block/components/layouts/Page";
import {HasPermission} from "starh-comp-auth";
import {SelectApplicationTenant} from "../../components/SelectApplicationTenant";
import {OverlayPanel} from "primereact/overlaypanel";
import {useRoleConfigs} from "../../hooks/useRoleConfigs";
import axios from "axios";

export const ManageRoles = () => {
    const {
        schema, fullColumns, fullTextSearchFields, advancedSearchFields
    } = useRoleConfigs()
    const {
        gridEditButton, gridDeleteButton, defaultPageHeader, defaultDialogFooter
    } = useGlobalRenders();
    const {
        permissionEndpoint, roleEndpoint, defaultDeleteItem, defaultAddEditItem, loadEditItem
    } = useDefaultsConfigs();

    const [application, setApplication] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [dialogMode, setDialogMode] = useState(null);
    const [formReset, setFormReset] = useState(null);
    const [roles, setRoles] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sourcePermissions, setSourcePermissions] = useState([]);
    const [targetPermissions, setTargetPermissions] = useState([]);
    const [allPermissions, setAllPermissions] = useState([]);

    const rolePanel = useRef(null);
    const tableRef = useRef();

    const entity = 'role';
    const baseEndpoint = roleEndpoint;
    const reset = () => { setFormReset(!formReset) }
    const loadData = () => {
        if (tableRef.current) {
            tableRef.current.reload();
            setLoading(false);
        }
    }
    const opItem = (op, data) => {
        data.applicationId = application?.code;
        setSourcePermissions(allPermissions)
        setTargetPermissions({})

        setDialogMode(op);
        if(data.id) {
            loadEditItem(baseEndpoint, data, data.id, (dataItem) => {
                dataItem.description = (dataItem.description) ? dataItem.description : '';
                setCurrentData(dataItem);
                onPermissionChange(dataItem.permissions)
                setShowDialog(true);
            })
        } else{
            setCurrentData(data);
            setShowDialog(true);
        }
    }
    const pageHeader = () => {
        return (<>
            {application && defaultPageHeader("Add " + entity, () => opItem('ADD', {})) }
        </>)
    }
    const dialogFooter = () => defaultDialogFooter(dialogMode, () => reset())

    useEffect(()=>{
        if(application){
            loadData();
        }
    }, [application])

    useEffect(()=>{
        loadAllPermissions();
    }, [])

    fullColumns.push({
        header: "Actions",
        headerStyle: {width: "105px"},
        renderer: (data) => {
            return <>
                {gridEditButton(() => opItem('EDIT', data), [])}
                {gridDeleteButton( () => {
                    defaultDeleteItem(baseEndpoint, data, entity, data.id, () => loadData())
                }, [])}
            </>
        }
    })

    const submitForm = (data) => {
        data.applicationId = application?.code;
        
        defaultAddEditItem(baseEndpoint, data, dialogMode, entity, data.id, () => {
            setShowDialog(false)
            loadData();
        })
    }

    const  loadAllPermissions = () =>{
        axios.get(permissionEndpoint)
            .then((res) =>{
                let values = res.data.content;
                values.sort((a, b) => a.name.localeCompare(b.name));
                setAllPermissions(values)
            })
            .catch((err)=>{})
            .finally(()=>{});
    }

    const onPermissionChange = (value) => {
        setTargetPermissions(value);
        const filtered = allPermissions.filter(p => !value.some((e)=>{return e.name === p.name}));
        setSourcePermissions(filtered)
    }

    const permissionItemTemplate = (item) => {
        return (
            <p><b>{item.name}</b><br/>{item.description}</p>
        );
    }

    const dialogContent = () => {
        return (<>
            <div className="mb-2">
                <Input withController={true} name={'name'} label="Name" readonly={dialogMode == 'EDIT'}/>
            </div>
            <div className="mb-2">
                <Textarea withController={true} name={'description'} label="Description"
                          defaultValue={''} placeholder={'Insert a description'} />
            </div>
            <div className="mb-4">
                <Number withController={true} name={'order'} label="Order" placeholder={'Insert here the order priority'} />
            </div>

            <div className="mb-2">
                <SearchablePickList
                    dataKey="name"
                    border={false}
                    withController={true}
                    label={"Permission"}
                    available={"Avaiable"}
                    selected={"Selected"}
                    name={"permissions"}
                    maxResults={2500}
                    nameField={"name"}
                    searchplaceholder={"Search permissions"}
                    itemTemplate={permissionItemTemplate}
                    searchable={true}
                    onChange={onPermissionChange}
                    value={targetPermissions}
                    idProperty={"name"}
                    source={sourcePermissions}
                />
            </div>
        </>)
    }

    return (<>
        <HasPermission permissions={["ROLE_MANAGE"]}>
            <Page title={"Manage Roles"} header={pageHeader()}>
                <SelectApplicationTenant
                    type={'APP'} placeholder={"Select Application..."}
                    onSelect={(sel) => {
                        setApplication(sel)
                        setCurrentData({ applicationId: sel?.code })
                    }}
                >
                    {(application) ? (<>
                        <AjaxTable
                            innerRef={tableRef}
                            endpoint={baseEndpoint}
                            columns={fullColumns}
                            fullTextSearchFields={fullTextSearchFields}
                            advancedSearchFields={advancedSearchFields}
                            searchButtonLabel="Search"
                            resetButtonLabel="Reset"
                            requestHeaders={{ ...{'x-appid': application.code} }}
                        />

                        <OverlayPanel ref={rolePanel} id="overlay_panel" style={{width: '450px'}}>
                            <div>
                                {(roles && roles.length > 0) ? (
                                    <ul>
                                        {roles.map((r) => (
                                            <li key={r}>{r}</li>
                                        ))}
                                    </ul>
                                ) : "No role for this user."}
                            </div>
                        </OverlayPanel>

                        <Form onSubmit={submitForm} schema={schema} defaultValues={currentData} reset={formReset}>
                            <Dialog visible={showDialog} style={{ minWidth: '80vw', maxHeight: '80vh' }}
                                    header={dialogMode + " " + entity} modal className="p-fluid"  appendTo="self"
                                    onHide={()=>{setShowDialog(false)}} footer={dialogFooter()} >
                                {dialogContent()}
                            </Dialog>
                        </Form>
                    </>) : (<></>)}
                </SelectApplicationTenant>
            </Page>
        </HasPermission>
    </>)
}