import {useTableFilter, useValidation} from "starh-comp-common";
import * as yup from "yup";
import {useGlobalRenders} from "./useGlobalRenders";


export const useTenantsConfigs = () => {
    const { shortcuts } = useTableFilter();
    const {
        stringRequired, string, bool, date
    } = useValidation()
    const {booleanGridRendered} = useGlobalRenders()

    const schema = yup.object().shape({
        name: stringRequired,
        description: string,
        admin: bool,
        enabled: bool,
        endDate: date.nullable(),
        regex: stringRequired,
    });

    const fullColumns = [
        {
            header: "Name",
            field: 'name',
            sortable: true,
        },
        {
            header: "Description",
            field: 'description',
            sortable: true,
        },
        {
            header: "Admin",
            field: 'admin',
            sortable: true,
            renderer: (rowData) => booleanGridRendered(rowData.admin)
        },
        {
            header: "Enabled",
            field: 'enabled',
            sortable: true,
            renderer: (rowData) => booleanGridRendered(rowData.enabled)
        },
        {
            header: "End Date",
            field: 'endDate',
            sortable: true,
            required: false,
            renderer: (rowData) => {
                // return (rowData.endDate) ? rowData.endDate[0]+'-'+('0' + rowData.endDate[1]).slice(-2)+'-'+('0' + rowData.endDate[2]).slice(-2) : null;
                return (rowData.endDate) ? rowData.endDate : '-';
            }
        }
    ]
    const fullTextSearchFields = ['name', 'description']

    const advancedSearchFields = [
        shortcuts.Text("Name", 'name'),
        shortcuts.Text("Description", 'description'),
        shortcuts.Bool("Admin", 'admin'),
        shortcuts.Bool("Enabled", 'enabled')
    ]

    const exportFields = [
        {
            header: "Name",
            field: 'name',
        },
        {
            header: "Description",
            field: 'description',
        },
        {
            header: "admin",
            field: 'admin',
        },
        {
            header: "Enabled",
            field: 'enabled',
        },
        {
            header: "End Date",
            field: 'endDate',
        }
    ]

    return {
        schema, fullColumns, exportFields, advancedSearchFields, fullTextSearchFields
    }

}
