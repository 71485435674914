import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {Loading} from "starh-comp-auth";
import {useParams} from "react-router-dom";
import {Translate, useTranslations} from "starh-comp-common";
import {useDefaultsConfigs} from "../../../../hooks/useDefaultsConfigs";
import _ from "lodash";
import axios from "axios";
import {FXFormPage} from "./FxFormPage";
import {Messages} from "primereact/messages";
import * as PropTypes from "prop-types";


export const FXCreateModify = (props) => {

    const { id } = useParams();
    const messages = useRef();
    const {_t} = useTranslations()

    const {baseFormErrorResponse} = useDefaultsConfigs()
    const [ready, setReady] = useState(false);
    const [editMode, setEditMode] = useState();
    const [labels, setLabels] = useState({
        title:"",
        subtitle:"",
        gotoHomeMessage:"",
        successDialogTitle:"",
        successDialogMessage:"",
    });
    const [loading, setLoading] = useState(true);
    const [permissions,setPermissions] = useState([]);
    const [defaults,setDefaults] = useState({})
    const [values,setValues] = useState({})



    useImperativeHandle(props.innerRef, () => ({

        setDefaults: (defs) => {
            setDefaults(defs)
        },
        setFieldValue: (field,value) => {
            let newVal = {...values}
            newVal[field] = value
            setValues(newVal)
        },

    }));


    useEffect(()=>{
        setEditMode(!_.isNil(id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        updateLabels();
        if(editMode){

            setPermissions(props.editPermissions)
            let getEndpoint = props.getEndpoint ? props.getEndpoint : props.endpoint
            axios.get(getEndpoint.concat("/").concat(id))
                .then((res)=>{
                    populateFields(res.data)
                })
                .catch(err=>{
                    console.log(err? err : '')
                    messages?.current?.show({severity: 'error', summary: ("generic.summary"), detail: ("generic.exc-generic"), sticky:true, closable: false});
                    setReady(false)
                    setLoading(false);
                })
        }
        else if(editMode === false){

            setReady(true)
            setPermissions(props.createPermissions)
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[editMode])

    const updateLabels = () => {
        if(editMode  && !props.forcePost ){
            setLabels({
                title:(props.labelPrefix.concat(" Edit")),
                subtitle:(props.labelPrefix.concat("Edit")),
                gotoHomeMessage:(props.labelPrefix.concat(" Cancel")),
                successDialogTitle:(props.labelPrefix.concat("Success")),
                successDialogMessage:props.labelPrefix.concat("Operation succesfully complete!"),
            })
        }else{
            setLabels({
                title:(props.labelPrefix.concat(" Create")),
                subtitle:(props.labelPrefix.concat("Creation")),
                gotoHomeMessage:(props.labelPrefix.concat("Are you sure you want to cancel creation process?")),
                successDialogTitle:(props.labelPrefix.concat("Success")),
                successDialogMessage:props.labelPrefix.concat("Operation succesfully complete"),
            })
        }

    }
    const populateFields = data => {

        if(props.populateFields){
            let defs = props.populateFields(data)
            setDefaults(defs)
        }

        setLoading(false);
        setReady(true)
    }

    const handleErrorResponse = (e) => {
        return {...baseFormErrorResponse("generic.exc-conflict"), ...props.errorResponse(e)}
    }

    const successDialogBody = (data) => {
        if (props.successDialogAttributes) {
            let tBody = {}

            // props.successDialogAttributes.forEach(attr => {
            //     tBody[attr] = data[attr]
            // })

            return _t(labels.successDialogMessage, tBody)
        }

        return ("generic.dialog-defaultconfirm")
    }

    return (
        <FXFormPage
            endpoint={editMode && !props.forcePost ? (props.editEndpoint ? props.editEndpoint : props.endpoint) : (props.createEndpoint ? props.createEndpoint : props.endpoint)}
            title={labels.title}
            subtitle={labels.subtitle}
            gotoHomeMessage={labels.gotoHomeMessage}
            successDialogTitle={labels.successDialogTitle}
            schema={editMode ? props.editSchema : props.createSchema}
            beforeSend={props.beforeSend}
            successDialogBody={successDialogBody}
            errorResponse={handleErrorResponse}
            defaults={defaults}
            onReset={props.resetFunction}
            permissions={permissions}
            showClear={!editMode}
            returnPage={props.returnPage}
            showSubmit={ready}
            formMethod={editMode && !props.forcePost ? "PUT" : "POST"}
            confirmBeforeSendMessage={props.confirmBeforeSendMessage}
            confirmBeforeSend={props.confirmBeforeSend}
            confirmAcceptLabel={props.confirmAcceptLabel}
            confirmRejectLabel={props.confirmRejectLabel}
            pageId={editMode? props.pageIdModify : props.pageIdCreate}

        >

            <Messages ref={messages}></Messages>

            {loading && ( <Loading />)}
            {ready && (
                <>
                    {props.children}
                </>
            )}
        </FXFormPage>


    )
}

FXCreateModify.propTypes = {

    labelPrefix : PropTypes.string.isRequired,
    returnPage: PropTypes.string.isRequired,
    createEndpoint: PropTypes.string,
    editEndpoint: PropTypes.string,
    getEndpoint: PropTypes.string,
    endpoint: PropTypes.string.isRequired,
    createPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    editPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    createSchema: PropTypes.object.isRequired,
    editSchema: PropTypes.object.isRequired,
    resetFunction: PropTypes.func.isRequired,
    beforeSend:PropTypes.func.isRequired,
    populateFields: PropTypes.func.isRequired,
    additionalFooter: PropTypes.any,
    toolbar: PropTypes.any,
    innerRef: PropTypes.any,

    successDialogAttributes: PropTypes.array,
    confirmBeforeSend: PropTypes.bool,
    forcePost: PropTypes.bool,
    errorResponse: PropTypes.func,
    confirmBeforeSendMessage: PropTypes.func,

    confirmAcceptLabel: PropTypes.any,
    confirmRejectLabel: PropTypes.any,

    children: PropTypes.node,
    pageId: PropTypes.string,
    pageIdCreate: PropTypes.string,
    pageIdModify: PropTypes.string
};

FXCreateModify.defaultProps = {
    beforeSend: (data) => {
        return data
    },

    errorResponse: () => {
        return {}
    },

    successDialogAttributes: ["name"],

    confirmBeforeSend: false,
    confirmBeforeSendMessage: (data) => { // eslint-disable-line no-unused-vars
        return <Translate value={"generic.dialog-defaultconfirm"}></Translate>
    },

    confirmAcceptLabel: <Translate value={"Save"}></Translate>,
    confirmRejectLabel: <Translate value={"Cancel"}></Translate>,
    forcePost: false
}
