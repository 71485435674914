import {Page} from "../../themes/hya-prime-block/components/layouts/Page";
import {TabPanel, TabView} from "primereact/tabview";
import {ProfileEdit} from "../../components/user/ProfileEdit";
import {ChangePassword} from "../../components/user/ChangePassword";


export const UserProfile = () => {
    return (
        <Page title="Account" subtitle="This page allows you to manage your profile">
            <TabView id="account-edit">
                <TabPanel header="Change Params" leftIcon="pi pi-user" headerStyle={{color:"red"}}>
                    <ProfileEdit/>
                </TabPanel>
                <TabPanel header="Change Password" leftIcon="pi pi-lock">
                    <ChangePassword/>
                </TabPanel>
            </TabView>
        </Page>
    )
}
