import React, {useEffect, useState} from "react";
import {DatePicker, Form, Input, Password} from "starh-comp-common";
import FormatDate from "../../utils/FormatDate";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import axios from "axios";
import {PickList} from "primereact/picklist";
import {InputSwitch} from "primereact/inputswitch";
import moment from "moment";

export const CreateModifyUser = (props) => {

    const tenantId = props.tenantId
    const dialogMode = props.dialogMode
    const roles = props.roles
    const id = props.userId
    const schema = props.schema

    const [defaults, setDefaults] = useState({})
    const {userEndpoint, userAccountEndpoint} = useDefaultsConfigs()

    const [actualDate, setActualDate] = useState(new Date())
    const [source, setSource] = useState([])
    const [target, setTarget] = useState([])
    const [active, setActive] = useState(false)

    useEffect(() => {
        let myActualDate = new Date()

        myActualDate.setHours(0)
        myActualDate.setMinutes(0)
        myActualDate.setSeconds(0)

        setActualDate(myActualDate)

        if (dialogMode === "ADD") {
            setDefaults({
                accountValidityStart: myActualDate
            })
            setSource(roles)
        } else if (dialogMode === "EDIT") {
            axios.get(userEndpoint + "/" + id)
                .then(res => {
                    let data = {...res.data,
                        accountValidityStart: res.data.accountValidityStart ? moment(res.data.accountValidityStart).toDate(): undefined,
                        accountValidityEnd: res.data.accountValidityEnd ? moment(res.data.accountValidityEnd).toDate(): undefined}
                    setDefaults(data)
                    setActive(res.data.active)
                })
        }

    }, []);

    useEffect(() => {
        if (defaults.roles) {
            let _source = []
            let _target = []

            roles.forEach(role => {
                if (defaults.roles.some(e => e.id === role.id)) {
                    _target.push(role)
                } else {
                    _source.push(role)
                }
            })

            setTarget(_target)
            setSource(_source)
        }
    }, [defaults])

    const beforeSend = (data) => {
        if (dialogMode !== "PWD") {
            data.tenantId = tenantId;
            data.roles = target
            data.active = active

            if (data.accountValidityStart) {
                data.accountValidityStart = FormatDate(data.accountValidityStart, "YYYY-mm-dd")
            } else {
                delete data.accountValidityStart
            }

            if (data.accountValidityEnd !== null && data.accountValidityEnd !== undefined) {
                data.accountValidityEnd = FormatDate(data.accountValidityEnd, "YYYY-mm-dd")
            } else {
                delete data.accountValidityEnd
            }

            if (dialogMode === "ADD") {
                axios.post(userEndpoint, data)
                    .then(() => props.onHide())
                    .catch(e => console.error(e))
            } else {
                axios.put(userEndpoint + "/" + id, data)
                    .then(() => props.onHide())
                    .catch(e => console.error(e))
            }
        } else {
            if (id && data.newPassword === data.confirmPassword) {
                let endpoint = userAccountEndpoint + "/" + id + "/reset-pwd?newPwd=" + data.newPassword;

                axios.post(endpoint, data)
                    .then(() => props.onHide())
                    .catch(e => console.error(e))
            }
        }
    }

    const onClearDate = () => {
        return actualDate
    }

    const pickListOnChange = (e) => {
        setTarget(e.target)
        setSource(e.source)
    }

    const pickListTemplate = (item) => {
        return (
            <>
                <p><b>{item.name}</b> | <b>{item.applicationId}</b></p>
            </>
        )
    }

    return (
        <Dialog style={{width: '75vw'}}
                visible={true}
                closeOnEscape={true}
                onHide={props.onHide}
                header={props.header}>
            <Form schema={schema} onSubmit={beforeSend} defaultValues={defaults}>
                <div className="grid fluid">
                    {dialogMode !== "PWD" ?
                        <>
                            <div className="col-12 md:col-6">
                                <Input type={'text'} withController={true} name="firstName" label={"First Name"}/>
                            </div>
                            <div className="col-12 md:col-6">
                                <Input type={'text'} withController={true} name="lastName" label={"Last Name"}/>
                            </div>
                            <div className="col-12 md:col-6">
                                <Input type={'text'} withController={true} name="email" label={"Email"}
                                       style={{textTransform: 'lowercase'}}/>
                            </div>
                            <div className="col-12 md:col-6">
                                <label className="block">Active</label>
                                <div className="p-1 pl-0">
                                    <InputSwitch checked={active} onChange={e => setActive(e.value)}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="grid fluid">
                                    <div className="col-12 md:col-6">
                                        <DatePicker withController={true} name="accountValidityStart"
                                                    label={"Validity Start"} onClearDate={onClearDate}
                                                    minDate={actualDate} readOnlyInput={true}/>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <DatePicker withController={true} name="accountValidityEnd"
                                                    label={"Validity End"} minDate={actualDate} readOnlyInput={true}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <PickList source={source}
                                          target={target}
                                          sourceStyle={{height: "100%"}}
                                          targetStyle={{height: "100%"}}
                                          onChange={pickListOnChange}
                                          itemTemplate={pickListTemplate}/>
                            </div>
                        </> :
                        <>
                            <div className="col-12">
                                <Password withController={true} name={"newPassword"}
                                          label="New Password"/>
                            </div>
                            <div className="col-12">
                                <Password withController={true} name={"confirmPassword"}
                                          label="Confirm new password"/>
                            </div>
                        </>
                    }
                    <div className="col-6 text-left">
                        <Button label={"Cancel"} type="button" icon="pi pi-times" onClick={props.onHide}/>
                    </div>
                    <div className="col-6 text-right ">
                        <Button label={"Save"} type="submit" icon="pi pi-check" autoFocus/>
                    </div>
                </div>
            </Form>
        </Dialog>
    )
}