import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import {useTranslations} from "starh-comp-common";

export const Access = () => {
	const history = useHistory();
	const {_t} = useTranslations()

	return (
		<div className="exception-body accessdenied">
			<div className="exception-panel" style={{ position:"relative" }}>
				<div className="exception-content">
					<img src="/assets/layout/images/pages/icon-access.svg" alt="" />
					<h1>{_t["page.access.title"]}</h1>
					<p>{_t["page.access.body"]}</p>
					<Button label={_t["page.generic.back"]} icon="pi pi-arrow-left" onClick={() => { history.goBack() }} />
				</div>
			</div>
		</div>
	)
}