import React, {useState, useEffect} from 'react';
import {
    Input, Number, Switch, SimpleDropdown, SimpleDropdownMultipleSelect
} from "starh-comp-common";
import axios from "axios";

import {useDefaultsConfigs} from "../../hooks/useDefaultsConfigs";
import PropTypes from "prop-types";
import {FXCreateModify} from "../../themes/hya-prime-block/components/layouts/FXCreateModify";
import {useNotificationConfigConfig} from "../../hooks/useNotificationConfigConfig";


export const CreateModifyNotificationConfig = () => {
    const protocols = [{label:'protocol', key:'smtp'},{label:'protocol', key: 'imap'}]
    const defaultEncodings = [{label:'defaultEncoding', key:'UTF-8'},{label:'defaultEncoding', key: 'UTF-16'}]
    const {tenantEndpoint} = useDefaultsConfigs()
    const {schema,schemaModify} = useNotificationConfigConfig()
    const [isEdit, setIsEdit] = useState(false)
    const [currentConfig, setCurrentConfig] = useState()
    const [tenantList, setTenantList] = useState()

    const {
        notificationConfigEndpoint,
    } = useDefaultsConfigs()

    useEffect(() => {
        axios.get(tenantEndpoint)
            .then((res) =>
            {

                setTenantList(res.data.content.map(({name,nameUp=name.toUpperCase()}) => ({name,nameUp})))
            })
            .catch(err => {
                console.error(err)
            })
    }, [])



    const populateFields = data => {

        data.tenantIds= data.tenantIds.map((name,{nameUp=name.toUpperCase()}) => ({name,nameUp}))

        setCurrentConfig(data)
        setIsEdit(true)

        return data;

    }


    const beforeSend = (data) => {

        data.starttls = data.starttls || false;
        data.defaultConfig = data.defaultConfig || false;
        data.protocol = data.protocol.key
        data.tenantIds= data.tenantIds.map(({name}) => (name))
        data.defaultEncoding = data.defaultEncoding.key
        if(!data.protocol)
        {
            data.protocol = currentConfig?.protocol
        }
        if(!data.defaultEncoding)
        {
            data.defaultEncoding = currentConfig?.defaultEncoding
        }


        return data;
    }

    const resetForm = () => {

    }

    const displayError = (data) => {
        let myFunction = () => {
            return data.response?.data?.message
        }
        let status = data.response?.status;
        let objectRes = new Object();
        objectRes[status] = () => myFunction();
        return objectRes
    }

    return (<>
        <FXCreateModify
            labelPrefix="Mail Config"
            returnPage="/notification/config"
            endpoint={notificationConfigEndpoint}
            createPermissions={["MANAGE_NOTIFY_CONFIG"]}
            editPermissions={["MANAGE_NOTIFY_CONFIG"]}
            createSchema={schema}
            editSchema={schemaModify}
            resetFunction={resetForm}
            beforeSend={beforeSend}
            errorResponse={displayError}
            populateFields={populateFields}
        >
            <div className="grid fluid">
                <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2"><Input withController={true} name={'name'} label={"Name *"}></Input></div>
                <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2">
                    <SimpleDropdownMultipleSelect
                        nameField={"name"}
                        withController={true}
                        placeholder={"Select a tenant"}
                        name="tenantIds" label={"Tenant Ids *"}
                        items={tenantList}
                        showClear={true}
                    /></div>
                <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2"><Input withController={true} name={'host'} label="Host *"></Input></div>
                <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2"><Number useGrouping={false} withController={true} name={'port'}  min={0} minFractionDigits={0} label="Port *"></Number></div>
                <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2"><Input withController={true} name={'username'} label="Username *"></Input></div>
                <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2"><Input withController={true} name={'password'} label="Password *" ></Input></div>
                <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2"><SimpleDropdown nameField={'key'} label="Protocol *" items={protocols} withController={true} name={'protocol'} placeholder= {isEdit ? currentConfig?.protocol : "Select a protocol"  }  /></div>
                <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2"><SimpleDropdown nameField={'key'} label="Default Encoding *" items={defaultEncodings} withController={true} name={'defaultEncoding'} placeholder= {isEdit ? currentConfig?.defaultEncoding : "Select a default encoding"  }  /></div>
                <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2"><Input withController={true} name={'sender'} label="Sender *"></Input></div>
                <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2"><Switch withController={true} name={'starttls'} label="Start TLS"></Switch></div>
                <div className="col-12 sm:col-4 md:col-4 lg:col-4 mb-2"><Switch withController={true} name={'defaultConfig'} label="Default Config"></Switch></div>
            </div>

        </FXCreateModify>

    </>)
}

CreateModifyNotificationConfig.propTypes = {
    data: PropTypes.object,
    modify: PropTypes.bool
}
